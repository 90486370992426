<template>
  <v-card>
    <v-card-text class="pb-0">
      <v-card-title class="px-0 pb-1 font-weight-regular">
        Optimise Route - Mapbox
      </v-card-title>
      Are you sure you want to optimise this
      {{ $store.state.global.pages.plan.titleSingular.toLowerCase() }}?
      <div>
        Warning: This action will overwrite any current driver/vehicle
        allocations and waypoints.
      </div>
      <v-row class="mt-3">
        <v-col cols="12" sm="6">
          <v-autocomplete
            auto-select-first
            label="Type"
            v-model="type"
            :items="typeOptions"
            item-value="value"
            item-text="text"
            :hint="typeHint"
            persistent-hint
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
            auto-select-first
            label="Traffic"
            v-model="traffic"
            :items="trafficOptions"
            item-value="value"
            item-text="text"
            :hint="trafficHint"
            persistent-hint
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text color="grey darken-1" @click="close">Cancel</v-btn>
      <v-btn text color="primary" :loading="loading" @click="optimise()"
        >OPTIMISE</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    close: Function,
    action: Function,
    loading: Boolean
  },
  data() {
    return {
      type: 'min-schedule-completion-time',
      traffic: 'mapbox/driving-traffic',
      typeOptions: [
        { text: 'Minimise fleet time', value: 'min-total-travel-duration' },
        { text: 'Minimise schedule', value: 'min-schedule-completion-time' }
      ],
      typeHints: [
        'Minimise the total time traveled by the fleet.',
        'Minimise the length of the longest schedule in the fleet.'
      ],

      trafficOptions: [
        { text: 'Approximated', value: 'mapbox/driving-traffic' },
        { text: 'Free flowing', value: 'mapbox/driving' }
      ],
      trafficHints: [
        'Decreases speed for potentially heavy-loaded roads.',
        'The route is calculated optimistically with no traffic influence.'
      ]
    }
  },
  computed: {
    typeHint() {
      return this.typeHints[
        this.typeOptions.findIndex(x => x.value === this.type)
      ]
    },
    trafficHint() {
      return this.trafficHints[
        this.trafficOptions.findIndex(x => x.value === this.traffic)
      ]
    }
  },
  methods: {
    optimise() {
      this.action(this.traffic, this.type)
    }
  }
}
</script>
