export default {
  data() {
    return {
      projectKeys: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Phone',
          value: 'contactPhone'
        },
        {
          text: 'ABN',
          value: 'abn'
        },
        {
          text: 'Billing',
          value: 'billType'
        },
        {
          text: 'Accounting ID',
          value: 'accountingContactId'
        },
        {
          text: 'Active',
          value: 'active'
        }
      ],
      sourceKeys: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Phone',
          value: 'contactPhone'
        },
        {
          text: 'ABN',
          value: 'abn'
        },
        {
          text: this.$store.state.global.pages.project.titleSingular,
          value: 'project.name'
        },
        {
          text: 'Details',
          value: 'details'
        },
        {
          text: 'Location',
          value: 'location'
        },
        {
          text: 'Accounting ID',
          value: 'accountingContactId'
        },
        {
          text: `Active ${this.$store.state.global.pages.project.titleSingular}`,
          value: 'project.active'
        },
        {
          text: `Deleted ${this.$store.state.global.pages.project.titleSingular}`,
          value: 'project.deletedAt'
        },
        {
          text: 'Request',
          value: 'request'
        }
      ],
      destinationKeys: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Phone',
          value: 'contactPhone'
        },
        {
          text: 'ABN',
          value: 'abn'
        },
        {
          text: 'Details',
          value: 'details'
        },
        {
          text: 'Accounting ID',
          value: 'accountingContactId'
        },
        {
          text: 'Site ID',
          value: 'number'
        },
        {
          text: 'Tipping Docket Required',
          value: 'tippingDocketRequired'
        },
        {
          text: 'Request',
          value: 'request'
        }
      ],
      itemTypeKeys: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Request',
          value: 'request'
        }
      ],
      contractorKeys: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Phone',
          value: 'contactPhone'
        },
        {
          text: 'ABN',
          value: 'abn'
        },
        {
          text: 'Admin Fee',
          value: 'adminFeePercentage'
        },
        {
          text: 'Override Cost',
          value: 'override'
        },
        {
          text: 'Accounting ID',
          value: 'accountingContactId'
        },
        {
          text: 'Active',
          value: 'active'
        },
        {
          text: 'Request',
          value: 'request'
        }
      ],
      driverKeys: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: this.$store.state.global.pages.contractor.titleSingular,
          value: 'contractor.name'
        },
        {
          text: 'Phone',
          value: 'contactPhone'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Active',
          value: 'active'
        },
        {
          text: `Active ${this.$store.state.global.pages.contractor.titleSingular}`,
          value: 'contractor.active'
        },
        {
          text: `Deleted ${this.$store.state.global.pages.contractor.titleSingular}`,
          value: 'contractor.deletedAt'
        },
        {
          text: 'Request',
          value: 'request'
        },
        {
          text: 'Username',
          value: 'username'
        }
      ],
      vehicleKeys: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Type',
          value: 'vehicleType.name'
        },
        {
          text: 'Details',
          value: 'details'
        },
        {
          text: this.$store.state.global.pages.contractor.titleSingular,
          value: 'contractor.name'
        },
        {
          text: 'Active',
          value: 'active'
        },
        {
          text: `Active ${this.$store.state.global.pages.contractor.titleSingular}`,
          value: 'contractor.active'
        },
        {
          text: `Deleted ${this.$store.state.global.pages.contractor.titleSingular}`,
          value: 'contractor.deletedAt'
        },
        {
          text: 'Request',
          value: 'request'
        }
      ]
    }
  }
}
