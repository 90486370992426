<template>
  <v-card flat class="card-outlined">
    <v-card-text class="pb-0 d-flex justify-end align-center">
      <ColumnSelector
        :defaultHeaders="defaultHeaders"
        :possibleHeaders="possibleHeaders"
        toolTipText="Column selector"
        :onChanged="val => (defaultHeaders = val)"
      />
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="compliances"
      :loading="loadingCompliances"
      :options.sync="pagination"
      :server-items-length="count"
      mobile-breakpoint="0"
      :footer-props="{
        'items-per-page-options': [10, 20, 40, 50],
        showFirstLastPage: true
      }"
      :search="filterBy"
    >
      <template v-slot:no-data>
        No matching records found
      </template>
      <template v-slot:loading>
        Loading records...
      </template>
      <template v-slot:item="{ item }">
        <Row
          :headers="headers"
          :item="item"
          :key="item.id"
          showEdit
          defaultDialog="detailsDialog"
        >
          <template v-slot:details="{ dialog, close, open, openEditDialog }">
            <ComplianceDetails
              :dialog="dialog"
              :item="item"
              :loading="loading"
              :close="close"
              :open="open"
              :openEditDialog="openEditDialog"
            />
          </template>
          <template v-slot:delete="{ dialog, close }">
            <DeleteDialog
              :dialog="dialog"
              :page="titleSingular"
              :subject="item"
              :action="deleteCompliance"
              :close="close"
            />
          </template>
          <template v-slot:edit="{ dialog, close }">
            <ComplianceEdit
              :dialog="dialog"
              :item="item"
              :loading="loading"
              :action="editCompliance"
              :close="close"
            />
          </template>
        </Row>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  deleteCompliance,
  updateCompliance
} from '../../services/requests/compliances'
import Store from '@/store/computed/storeHelpers'
import getCompliances from '../../services/mixins/getTransactionFiles/getCompliances'
import Headers from './mixins/complianceHeaders'
import HeaderFilters from '../../services/mixins/headerFilters'
import Compliance from '../../services/models/compliance'
import Row from '../misc/row/Row'
import ComplianceDetails from './ComplianceDetails'
import ComplianceEdit from './ComplianceEdit'
import DeleteDialog from '../misc/DeleteDialog'
import ColumnSelector from '../misc/shared/ColumnSelector'
export default {
  mixins: [getCompliances, Headers, HeaderFilters],
  components: {
    Row,
    ComplianceDetails,
    ComplianceEdit,
    DeleteDialog,
    ColumnSelector
  },
  data() {
    return {
      loading: false
    }
  },
  watch: {
    compliances() {
      this.selected = []
      this.selectedAll = false
    },
    selectedUserId() {
      this.getCompliances()
    },
    selectedContractorId() {
      this.getCompliances()
    },
    selectedDriverId() {
      this.getCompliances()
    },
    selectedVehicleId() {
      this.getCompliances()
    },
    dateRangeStart() {
      this.getCompliances()
    },
    dateRangeEnd() {
      this.getCompliances()
    },
    filterCol() {
      this.getCompliances()
    },
    filterBy() {
      this.getCompliances()
    }
  },
  computed: {
    selectedDriverId: Store.getSet({
      store: 'global',
      prop: 'selectedDriverId'
    }),
    selectedVehicleId: Store.getSet({
      store: 'global',
      prop: 'selectedVehicleId'
    }),
    selectedContractorId: Store.getSet({
      store: 'global',
      prop: 'selectedContractorId'
    }),
    selectedUserId: Store.getSet({ store: 'global', prop: 'selectedUserId' }),
    dateRangeStart: Store.getSet({ store: 'global', prop: 'dateRangeStart' }),
    dateRangeEnd: Store.getSet({ store: 'global', prop: 'dateRangeEnd' }),
    filterCol: Store.getSet({ store: 'compliances', prop: 'filterCol' }),
    filterBy: Store.getSet({ store: 'compliances', prop: 'filterBy' }),
    selected: Store.getSet({ store: 'compliances', prop: 'selected' }),
    selectedAll: Store.getSet({ store: 'compliances', prop: 'selectedAll' }),
    selectedCount() {
      return this.selected.length
    }
  },
  methods: {
    toggleSelectAll({ items }) {
      if (this.selected.length == 0) {
        const openItems = items.filter(({ isClosed }) => !isClosed)
        this.selected = openItems
      } else {
        this.selected = []
      }
    },
    toggleSelected(val) {
      if (val.value) {
        this.selected.push(val.object)
      } else {
        const index = this.selected.indexOf(val.object)
        this.selected.splice(index, 1)
      }
    },
    async deleteCompliance(compliance) {
      try {
        this.loading = true
        const result = await deleteCompliance(compliance.id)
        if (result) {
          this.$store.commit('compliances/deleteFromArrayStateByValue', {
            prop: 'compliances',
            value: compliance
          })
          this.snackDeleted()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    async editCompliance(compliance, file) {
      this.loading = true
      const formData = new FormData()
      if (file) formData.append('file', file)
      formData.append('model', compliance.model)
      formData.append('type', compliance.type)
      formData.append('expiryDate', compliance.expiryDate)
      formData.append('consoleEdit', true)
      if (compliance.notes) formData.append('notes', compliance.notes)
      try {
        const result = await updateCompliance(compliance.id, formData)
        const updated = new Compliance(result.data.result)
        this.$store.commit('compliances/updateArrayStateById', {
          prop: 'compliances',
          editedValue: updated
        })
        this.loading = false
        this.snackUpdated()
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    }
  }
}
</script>
