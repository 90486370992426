export default {
  computed: {
    userName() {
      if (!this.selectedUserId || !this.users.length) {
        return null
      } else {
        const obj = this.getObjectFromArray(
          this.users,
          'id',
          this.selectedUserId
        )
        const nameArray = obj.name.split(' ')
        return nameArray[0]
      }
    },
    contractorName() {
      if (
        (!this.contractorId && !this.selectedContractorId) ||
        !this.contractors.length
      ) {
        return null
      } else {
        const id = this.contractorId
          ? this.contractorId
          : this.selectedContractorId
        const obj = this.getObjectFromArray(this.contractors, 'id', id)
        return obj.name
      }
    },
    projectName() {
      if (
        (!this.projectId && !this.selectedProjectId) ||
        !this.projects.length
      ) {
        return null
      } else {
        const id = this.projectId ? this.projectId : this.selectedProjectId
        const obj = this.getObjectFromArray(this.projects, 'id', id)
        return obj.name
      }
    },
    sourceName() {
      if ((!this.sourceId && !this.selectedSourceId) || !this.sources.length) {
        return null
      } else {
        const id = this.sourceId ? this.sourceId : this.selectedSourceId
        const obj = this.getObjectFromArray(this.sources, 'id', id)
        return obj.name
      }
    },
    destinationName() {
      if (
        (!this.destinationId && !this.selectedDestinationId) ||
        !this.destinations.length
      ) {
        return null
      } else {
        const id = this.destinationId
          ? this.destinationId
          : this.selectedDestinationId
        const obj = this.getObjectFromArray(this.destinations, 'id', id)
        return obj.name
      }
    },
    vehicleName() {
      if (
        (!this.vehicleId && !this.selectedVehicleId) ||
        !this.vehicles.length
      ) {
        return null
      } else {
        const id = this.vehicleId ? this.vehicleId : this.selectedVehicleId
        const obj = this.getObjectFromArray(this.vehicles, 'id', id)
        return obj.name
      }
    },
    vehicleTypeName() {
      if (!this.selectedVehicleTypeId || !this.vehicleTypes.length) {
        return null
      } else {
        const id = this.selectedVehicleTypeId
        const obj = this.getObjectFromArray(this.vehicleTypes, 'id', id)
        return obj.name
      }
    },
    itemTypeName() {
      if (!this.selectedItemTypeId || !this.itemTypes.length) {
        return null
      } else {
        const id = this.selectedItemTypeId
        const obj = this.getObjectFromArray(this.itemTypes, 'id', id)
        return obj.name
      }
    },
    driverName() {
      if ((!this.driverId && !this.selectedDriverId) || !this.drivers.length) {
        return null
      } else {
        const id = this.driverId ? this.driverId : this.selectedDriverId
        const obj = this.getObjectFromArray(this.drivers, 'id', id)
        return obj.name
      }
    },
    reportName() {
      if (!this.selectedReportId || !this.reports.length) return null
      const id = this.selectedReportId
      const obj = this.getObjectFromArray(this.reports, 'id', id)
      return obj.name
    }
  }
}
