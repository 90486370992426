import Vue from 'vue'
import BasicListItem from '@/components/misc/BasicListItem'
import DatetimePicker from 'vuetify-datetime-picker'
import JsonCSV from 'vue-json-csv'

Vue.use(DatetimePicker)

Vue.component('BasicListItem', BasicListItem)
Vue.component('downloadCsv', JsonCSV)

Vue.mixin({
  computed: {
    titleSingular() {
      return this.$store.state.global.pages[this.$route.name].titleSingular
    },
    globalSettings() {
      return this.$store.state.settings.settings
    },
    userAuthClass() {
      let route = this.$route.name
      // if (route == 'project' || route == 'source' || route == 'rate') {
      //   route = 'client'
      // }
      if (route == 'billingPeriod') {
        route = 'settings'
      }
      if (route == 'approvedBillLine') {
        route = 'bill'
      }
      // if (route == 'vehicle' || route == 'driver' || route == 'vehicleType') {
      //   route = 'contractor'
      // }
      if (route == 'confirmedTrip') {
        route = 'rcti'
      }
      if (route == 'confirmedTripBilling') {
        route = 'bill'
      }
      // if (route == 'itemType') {
      //   route = 'destination'
      // }
      return this.$store.state.user.userAuthClass[`${route}Write`]
    },
    userShowCost() {
      return this.$store.state.user.userAuthClass.showCost
    },
    userShowSell() {
      return this.$store.state.user.userAuthClass.showSell
    },
    isAdministrator() {
      return this.$store.state.user.userAuthClass.name == 'Administrator'
    },
    daysToLicenceExpiry() {
      const expiry = this.globalSettings.licenceExpiry
      const currentDate = new Date()
      const expiryDate = new Date(expiry)
      const diffInTime = expiryDate.getTime() - currentDate.getTime()
      const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24))
      return diffInDays
    },
    showExpiryWarning() {
      return (
        this.globalSettings.licenceExpiry &&
        this.daysToLicenceExpiry <= 7 &&
        this.isAdministrator
      )
    },
    licenceExpired() {
      return this.globalSettings.licenceExpiry && this.daysToLicenceExpiry <= 0
    }
  },
  methods: {
    snack(options) {
      this.$store.dispatch('global/displaySnackbar', options)
    },
    snackCreated() {
      this.snack({ text: `${this.titleSingular} created!`, color: 'green' })
    },
    snackUpdated() {
      this.snack({ text: `${this.titleSingular} updated!`, color: 'green' })
    },
    snackDeleted() {
      this.snack({ text: `${this.titleSingular} deleted!`, color: 'amber' })
    },
    snackFormError() {
      this.snack({ text: 'Please correct form errors', color: 'red' })
    },
    getObjectPath(path, object) {
      const props = path.split('.')
      while (props.length > 1) {
        object = object[props.shift()]
      }
      object = object[props.shift()]
      return object
    },
    getObjectPathRootExists(path, object) {
      var props = path.split('.')
      while (props.length > 1) {
        object = object[props.shift()]
        if (!object) return false
      }
      return !!object
    },
    getObjectFromArray(array, prop, value) {
      return array.filter(item => {
        return item[prop] == value
      })[0]
    },
    searchByAttr(array, attr, value) {
      const arrLeng = array.length
      for (let i = 0; i < arrLeng; i++) {
        if (array[i][attr] === value) {
          return i
        }
      }
      return -1
    },
    getUTCTime(string) {
      if (!string) return string
      const date = new Date(Date.parse(string))
      let hours = date.getHours()
      let mins = date.getMinutes()
      if (hours < 10) hours = `0${hours}`
      if (mins < 10) mins = `0${mins}`
      return `${hours}:${mins}`
    },
    makeSingular(string) {
      return string.slice(-1) === 's' ? string.slice(0, -1) : string
    },
    addCommas(number) {
      const arr = number.split('.')
      return `${arr[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}${
        arr[1] ? '.' + arr[1] : ''
      }`
    }
  }
})
