<template>
  <div>
    <v-card flat class="card-outlined">
      <v-card-text>
        <CardHeader
          :title="
            `${$store.state.global.pages.docket.titleSingular} Line Entry`
          "
          :icon="$store.state.global.pages.docket.icon"
          :actions="controlActions"
          :message="message"
          :loading="loadingRate"
        />
        <v-form ref="addForm" @submit.prevent="add()">
          <v-row>
            <v-col cols="6" md="2">
              <v-autocomplete
                auto-select-first
                :label="
                  `* ${$store.state.global.pages.destination.titleSingular}`
                "
                :prepend-icon="$store.state.global.pages.destination.icon"
                v-model="destinationId"
                :items="filteredDestinations"
                item-value="id"
                item-text="name"
                :hint="destinationHint"
                clearable
                :rules="destinationRules"
                ref="destinationId"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="2">
              <v-autocomplete
                auto-select-first
                :label="`* ${$store.state.global.pages.itemType.titleSingular}`"
                prepend-icon="mdi-chevron-right"
                v-model="itemTypeId"
                :items="filteredItemTypes"
                item-value="id"
                item-text="name"
                :rules="itemTypeRules"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="2">
              <v-autocomplete
                auto-select-first
                label="* Units"
                :prepend-icon="$store.state.global.pages.destination.icon"
                v-model="sellUnit"
                :items="units"
                :rules="sellUnitRules"
                :disabled="!sellUnitEnabled"
              ></v-autocomplete>
            </v-col>

            <v-col cols="6" md="2">
              <v-text-field
                :label="
                  calculateQty ? `* Calculated Qty ( ${sellUnit})` : '* Qty'
                "
                prepend-icon="mdi-chevron-right"
                v-model.number="qty"
                ref="qty"
                :readonly="sellUnit === 'HOUR' || sellUnit === 'TONNE'"
                :rules="qtyRules"
                :disabled="loadingRate"
                @focus="showQtyDialog"
              >
                <template
                  v-slot:prepend
                  v-if="sellUnit == 'TONNE' || sellUnit == 'HOUR'"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" @click="qtyDialog = !qtyDialog">
                        mdi-cursor-default-click
                      </v-icon>
                    </template>
                    Show details
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6" md="2">
              <v-text-field
                v-if="showSell"
                label="* Sell Price"
                prepend-icon="mdi-chevron-right"
                v-model.number="sellPrice"
                ref="sellPrice"
                :disabled="priceDisabled"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="2">
              <v-text-field
                v-if="showSell"
                label="Total"
                prepend-icon="mdi-chevron-right"
                :value="sellTotal.toFixed(2)"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>

          <CostSelection
            :values="{
              costUnit: costUnit,
              costQty: costQty,
              costPrice: costPrice
            }"
            :costUnitEnabled="costUnitEnabled"
            :units="units"
            :disableCostQty="disableCostQty"
            :priceDisabled="priceDisabled"
            :showCost="showCost"
            :costTotal="costTotal"
            :qtyRules="qtyRules"
            :unitRules="costUnitRules"
            :onChange="updateValue"
            :colSize="2"
          />

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                color="indigo"
                label="Weighbridge #"
                prepend-icon="mdi-note"
                v-model="wbDocketNumber"
                :rules="wbDocketNumberRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Notes"
                prepend-icon="mdi-note"
                v-model="notes"
                :rules="notesRules"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-expand-transition>
                <v-row>
                  <v-col class="py-0 d-flex justify-end">
                    <v-tooltip
                      top
                      :disabled="
                        !!vehicleId &&
                          !!sourceId &&
                          !!destinationId &&
                          !!itemTypeId &&
                          !!date &&
                          !!globalSettings.useRate &&
                          !!rate
                      "
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-btn
                            :disabled="
                              !vehicleId ||
                                (globalSettings.useRate && !rate) ||
                                !date
                            "
                            color="primary"
                            outlined
                            type="submit"
                            class="mt-5 mb-5"
                          >
                            Add
                          </v-btn>
                        </div>
                      </template>
                      <span>{{ addButtonDisabledMessage }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-expand-transition>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-container fluid class="px-0">
      <v-row>
        <v-expand-transition>
          <v-col cols="12">
            <DocketLineTable
              :headers="lineHeaders"
              :items="lines"
              :date="date"
              :vehicleId="vehicleId"
              :sourceId="sourceId"
              :onEdit="onEdit"
              :onDelete="onDelete"
              :loading="loading"
              :loadingLines="loadingLines"
              showEdit
              :hideDetails="hideDetails"
              :showBilling="showBilling"
              :type="type"
              :isEditDocket="isEditDocket"
              :source="source"
              :vehicle="vehicle"
            />
            <div
              v-if="sellTotalForLines && showSell"
              class=" mx-3 mt-3 text-right title"
            >
              Total: ${{ sellTotalForLines.toFixed(2) }}
            </div>
          </v-col>
        </v-expand-transition>
      </v-row>
    </v-container>

    <v-dialog
      v-model="qtyDialog"
      max-width="1300px"
      @click:outside="() => (qtyDialog = false)"
      @keydown.esc="() => (qtyDialog = false)"
    >
      <QtyDialog
        :onChange="updateValue"
        :showMass="showMass"
        :showHours="showHours"
        :hours="hours"
        :destinationWeight="destinationWeight"
        :close="closeQty"
      >
        <template v-slot:mass="{ onChange }">
          <MassSelection
            v-if="qtyDialog"
            :values="{
              destinationWeight: destinationWeight,
              sourceWeight: sourceWeight,
              vehicleWeight: vehicleWeight
            }"
            :onChange="onChange"
            :destinationWeightRules="destinationWeightRules"
            :sourceWeightRules="sourceWeightRules"
            :vehicleWeightRules="vehicleWeightRules"
          />
        </template>
        <template v-slot:time="{ onChange }">
          <TimeSelection
            :values="{
              selectedRange: selectedRange,
              breaks: breaks || 0,
              travel: travel || 0,
              rangeList: rangeList,
              timesList: timesList
            }"
            :clearTimes="clearTimes"
            :onChange="onChange"
            :hours="hours"
            :breaksRules="breaksRules"
            :travelRules="travelRules"
          />
        </template>
      </QtyDialog>
    </v-dialog>
    <v-dialog
      v-model="rateDialog"
      max-width="900px"
      @click:outside="() => (rateDialog = false)"
      @keydown.esc="() => (rateDialog = false)"
    >
      <RateDetails
        v-if="!!rate && rateDialog"
        :dialog="rateDialog"
        :vehicleTypeId="vehicle ? vehicle.vehicleType.id : null"
        :itemTypeId="itemTypeId"
        :rateId="rate.id"
        :close="() => (rateDialog = false)"
      />
    </v-dialog>
  </div>
</template>

<script>
import { initTimeline } from '../utils/helpers'
import getWindowSize from '../../../services/mixins/getWindowSize'
import DocketValidation from '@/services/validation/docket'
import LineHeaders from './mixins/lineHeaders'
import Helpers from './mixins/helpers'
import FormatDates from '@/services/mixins/formatDates'
import FilterItemDestination from '@/services/mixins/filterItemDestination'

import CardHeader from '../../misc/shared/CardHeader'
import DocketLineTable from './DocketLineTable'
import CostSelection from '../components/CostSelection'
import QtyDialog from '../components/QtyDialog'
import MassSelection from '../components/MassSelection'
import TimeSelection from '../components/TimeSelection'
import RateDetails from '@/components/rates/RateDetails'
export default {
  mixins: [
    FilterItemDestination,
    FormatDates,
    DocketValidation,
    LineHeaders,
    Helpers,
    getWindowSize
  ],
  components: {
    CardHeader,
    DocketLineTable,
    CostSelection,
    QtyDialog,
    MassSelection,
    TimeSelection,
    RateDetails
  },
  props: {
    lines: {
      type: Array,
      required: true
    },
    date: {
      type: String,
      required: false
    },
    isDay: {
      type: Boolean,
      required: false
    },
    vehicleId: {
      type: Number,
      required: false
    },
    sourceId: {
      type: Number,
      required: false
    },
    onAdd: {
      type: Function,
      required: true
    },
    onEdit: {
      type: Function,
      required: true
    },
    onDelete: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    loadingLines: {
      type: Boolean,
      required: true
    },
    sellTotalForLines: {
      type: Number,
      required: false
    },
    isEditDocket: {
      type: Boolean,
      required: false,
      default: false
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    source: {
      type: Object,
      required: false
    },
    showBilling: {
      type: Boolean,
      required: false
    },
    type: {
      type: String,
      required: false
    },
    vehicle: {
      type: Object,
      required: false
    }
  },
  mounted() {
    const labelInterval = this.window.width >= 600 ? 2 : 6
    const { rangeList, timesList } = initTimeline(
      this.rangeList,
      this.timesList,
      labelInterval
    )
    this.rangeList = rangeList
    this.timesList = timesList
  },
  data() {
    return {
      rate: null,
      selectedRange: [48, 48],
      clearTimes: false,
      rangeList: [],
      timesList: [],
      destinationId: null,
      itemTypeId: null,
      notes: null,
      qty: 0,
      costQty: 0,
      start: null,
      end: null,
      showMore: false,
      sellPrice: 0,
      sellUnit: null,
      costPrice: 0,
      costUnit: null,
      destinationWeight: 0,
      sourceWeight: 0,
      vehicleWeight: 0,
      breaks: 0,
      travel: 0,
      wbDocketNumber: null,
      useAltRate: false,
      useNightRate: this.isEditDocket ? false : !this.isDay,
      useOverrideCost: false,
      sellRef: null
    }
  },
  computed: {
    addButtonDisabledMessage() {
      if (
        !this.vehicleId ||
        !this.sourceId ||
        !this.destinationId ||
        !this.itemTypeId ||
        !this.date
      ) {
        let arr = []
        if (!this.sourceId)
          arr.push(this.$store.state.global.pages.source.titleSingular)
        if (!this.date) arr.push('Date')
        if (!this.vehicleId)
          arr.push(this.$store.state.global.pages.vehicle.titleSingular)
        if (!this.destinationId)
          arr.push(this.$store.state.global.pages.destination.titleSingular)
        if (!this.itemTypeId)
          arr.push(this.$store.state.global.pages.itemType.titleSingular)

        if (arr.length >= 2) {
          const start = arr.slice(0, -2)
          const end = arr.slice(-2)
          return `Please select ${
            start.length ? start.join(', ') + ', ' : ''
          } ${end.join(' and ')} to continue`
        }
        return `Please select ${arr[0]} to continue`
      } else if (this.globalSettings.useRate && !this.rate) {
        return 'Rate is required'
      } else {
        return 'Add new line'
      }
    },
    lineHeaders() {
      return Number(this.window?.width) >= 600
        ? this.headers
        : this.mobileHeaders
    },
    showSell() {
      return this.$store.state.user.userAuthClass.showSell
    }
  },
  watch: {
    sellUnit() {
      this.clearValues()
      this.setQty()
    },
    useAltRate() {
      this.setPricing()
    },
    isDay() {
      console.log('isDay', this.isDay)
      this.useNightRate = !this.isDay
    },
    useNightRate() {
      this.setPricing()
    },
    useOverrideCost() {
      this.setPricing()
    },
    isSunday() {
      this.setPricing()
    },
    start() {
      this.setQty()
      this.updateTimeline()
    },
    end() {
      this.setQty()
      this.updateTimeline()
    },
    breaks() {
      this.setQty()
    },
    travel() {
      this.setQty()
    },
    destinationWeight() {
      this.setQty()
    },
    sourceId() {
      this.destinationId = null
      this.checkRate()
    },
    vehicleId() {
      this.checkRate()
    },
    destinationId() {
      this.checkRate()
    },
    itemTypeId() {
      this.checkRate()
    }
  },
  methods: {
    add() {
      if (!this.$refs.addForm.validate()) {
        this.snackFormError()
        return
      }
      const line = {
        id: this.lines.length + 1,
        destinationId: this.destinationId,
        itemTypeId: this.itemTypeId,
        qty: this.qty,
        costQty: this.costQty,
        costUnit: this.costUnit,
        costPrice: this.costPrice,
        sellUnit: this.sellUnit,
        sellPrice: this.sellPrice,
        destinationWeight: Number(this.destinationWeight),
        sourceWeight: Number(this.sourceWeight),
        vehicleWeight: Number(this.vehicleWeight),
        start: this.convertTime(this.start, new Date(this.date)),
        end: this.convertTime(this.end, new Date(this.date)),
        breaks: Number(this.breaks),
        travel: Number(this.travel),
        hours: this.hours,
        wbDocketNumber: this.wbDocketNumber,
        useAltRate: this.useAltRate,
        useNightRate: this.useNightRate,
        useOverrideCost: this.useOverrideCost,
        notes: this.notes,
        rate: this.rate,
        rateId: this.rate?.id,
        GPSConfirmed: 'NOT SET',
        tippingDocketConfirmed: 'NOT SET',
        massDeclarationConfirmed: 'NOT SET',
        waivedAmount: '0.0000',
        approvedBillingAmount: '0.0000',
        outstandingBillingAmount: (this.sellPrice * this.qty).toFixed(4),
        lineNumber: this.lines.length + 1
      }
      this.onAdd(line)
      this.clearTimes = false
      this.focusRef('destinationId')
      this.clear()
    },

    clear() {
      this.qty = 0
      this.costQty = 0
      this.start = null
      this.end = null
      this.destinationWeight = 0
      this.sourceWeight = 0
      this.vehicleWeight = 0
      this.breaks = 0
      this.travel = 0
      this.wbDocketNumber = null
      this.notes = null
      this.selectedRange = [48, 48]
    },
    closeQty() {
      this.setQty()
      this.focusRef('sellPrice')
      this.qtyDialog = false
    },
    updateValue(key, value) {
      this[key] = value
    },
    focusRef(ref) {
      if (this.$refs[ref]) this.$refs[ref].focus()
    }
  }
}
</script>
