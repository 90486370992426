<template>
  <span>
    <span
      class="show-route"
      v-if="planLines && !item.closed && planVehicles.length"
    >
      <v-btn color="primary" text @click="() => (confirmOptimiseDialog = true)">
        <v-icon left>
          {{ $store.state.global.pages.destination.icon }}
        </v-icon>
        <span>Optimise Route</span>
      </v-btn>
    </span>
    <v-dialog v-model="confirmOptimiseDialog" width="900px">
      <ConfirmOptimise
        :loading="loadingRouteData"
        :close="() => (confirmOptimiseDialog = false)"
        :action="optimiseRouteGeoapify"
      />
    </v-dialog>
  </span>
</template>

<script>
import ConfirmOptimise from './ConfirmOptimise'
import FormatDates from '@/services/mixins/formatDates'

export default {
  mixins: [FormatDates],
  components: {
    ConfirmOptimise
  },
  props: {
    updateLinesWithWaypoints: {
      type: Function,
      required: true
    },
    planLines: {
      type: Array,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    planVehicles: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      confirmOptimiseDialog: false,
      loadingRouteData: false,
      linesWithWayPointData: [],
      geoapifyAccessToken: process.env.VUE_APP_GEOAPIFY_API_KEY,
      defaultHours: 8,
      defaultItemM3: 0.001
    }
  },
  methods: {
    async optimiseRouteGeoapify(type, traffic) {
      this.loadingRouteData = true
      const jobDate = new Date(this.item.date)
      const totalVolume = this.planLines.reduce(
        (acc, line) =>
          acc + line.qty * (line.itemType.volume || this.defaultItemM3),
        0
      )
      const defaultCapacity = Math.ceil(totalVolume / this.planVehicles.length)
      const agents = this.createAgents(defaultCapacity, jobDate)
      const shipments = this.createShipments(jobDate)
      const requestOptions = this.createRequestOptions(
        agents,
        shipments,
        type,
        traffic
      )
      console.log(requestOptions)
      const query = await fetch(
        `https://api.geoapify.com/v1/routeplanner?apiKey=${this.geoapifyAccessToken}`,
        requestOptions
      )
      try {
        const response = await query.json()
        this.handleResponse(response)
      } catch (err) {
        this.loadingRouteData = false
        this.confirmOptimiseDialog = false
        console.log(err)
      }
    },
    handleResponse(response) {
      console.log(response)
      if (response.features) {
        this.linesWithWayPointData = []
        for (const feature of response.features) {
          const featureLines = this.processFeature(feature)
          for (const line of featureLines) {
            this.linesWithWayPointData.push(line)
          }
        }
        const lineIdsUpdated = this.linesWithWayPointData.map(line => line.id)
        const linesNotUpdated = this.planLines
          .filter(line => !lineIdsUpdated.includes(line.id))
          .map(line => {
            return {
              ...line,
              waypointIndex: null,
              delTime: null,
              vehicleId: null,
              driverId: null
            }
          })
        this.linesWithWayPointData = [
          ...this.linesWithWayPointData,
          ...linesNotUpdated
        ]
        this.updateLinesWithWaypoints(this.linesWithWayPointData)
        this.loadingRouteData = false
        this.confirmOptimiseDialog = false
      }
    },
    processFeature(feature) {
      let linesWithWaypoint = []
      for (const action of feature.properties.actions) {
        if (action.type !== 'delivery') continue
        const line = this.planLines.find(
          ({ id }) => id === Number(action.shipment_id)
        )
        if (line)
          linesWithWaypoint.push({
            ...line,
            waypointIndex: action.waypoint_index,
            delTime: this.getDelTime(
              this.item.date,
              this.item.start,
              action.start_time
            )
          })
      }
      const featureVehicle = this.planVehicles.find(
        ({ id }) => id.toString() == feature.properties.agent_id
      )
      return linesWithWaypoint.map(line => {
        return {
          ...line,
          vehicleId: featureVehicle.id,
          vehicle: featureVehicle,
          driverId: featureVehicle.driver?.id,
          driver: featureVehicle.driver
        }
      })
    },
    createRequestOptions(agents, shipments, type, traffic) {
      const body = {
        mode: this.item.travelMode || 'drive',
        agents,
        shipments,
        traffic: traffic || 'approximated',
        type: type || 'balanced'
      }
      // balanced	A balanced route is a compromise between three main factors: time, cost, and distance traveled.
      // short	Optimizes a route by distance.
      // less_maneuvers	Extends the balanced routing type, but adds additional penalties for maneuvers.
      console.log(body)
      const bodyString = JSON.stringify(body)
      return {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: bodyString
      }
    },
    createAgents(defaultCapacity, jobDate) {
      return this.planVehicles.map(vehicle => {
        const endLocation =
          vehicle?.driver?.long && vehicle?.driver?.lat
            ? [vehicle.driver.long, vehicle.driver.lat]
            : [this.item.source.long, this.item.source.lat]
        const driverWindow = vehicle?.driver?.availability
          ? this.getRelativeTimes(
              jobDate,
              this.item.start,
              vehicle?.driver?.availability
            )
          : [[0, 3600 * this.defaultHours]] // 8 hours
        const vehicleCapacity =
          Number((vehicle.capacity || defaultCapacity).toFixed(3)) * 1000
        return {
          id: vehicle.id.toString(),
          description: vehicle.name,
          start_location: [this.item.source.long, this.item.source.lat],
          delivery_capacity: vehicleCapacity,
          end_location: endLocation,
          time_windows: driverWindow
        }
      })
    },
    createShipments(jobDate) {
      return this.planLines.map(line => {
        const timeWindows =
          line?.destination?.deliveryWindow && this.item.start
            ? this.getRelativeTimes(
                jobDate,
                this.item.start,
                line?.destination?.deliveryWindow
              )
            : [[0, 3600 * this.defaultHours]]
        const amount =
          Number(
            ((line.itemType.volume || this.defaultItemM3) * line.qty).toFixed(3)
          ) * 1000
        return {
          id: line.id.toString(),
          pickup: {
            location: [this.item.source.long, this.item.source.lat],
            duration: 0,
            time_windows: [[0, 3600 * this.defaultHours]]
          },
          delivery: {
            location: [line.long, line.lat],
            duration: 900, //15 minutes
            time_windows: timeWindows
          },
          amount,
          description: line.destination.name
        }
      })
    },
    getRelativeTimes(date, start, windowsString) {
      const startDateTime = this.convertTime(start, date)
      return windowsString
        .split(',')
        .map(delWindow =>
          delWindow
            .split('-')
            .map(
              time =>
                (this.convertTime(time.trim(), date) - startDateTime) / 1000
            )
        )
    },
    getDelTime(date, start, delTimeInSecs) {
      const startDateTime = this.convertTime(start, date)
      return this.makeTimeForEntry(
        new Date(
          startDateTime.setSeconds(startDateTime.getSeconds() + delTimeInSecs)
        )
      )
    }
  }
}
</script>

<style lang="scss" scoped></style>
