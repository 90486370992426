import Axios from 'axios'
import { hasAccessTokenExpired, refreshAccessToken } from './authentication'

const ApiInstance = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + 'api/'
})

ApiInstance.interceptors.request.use(
  async function(config) {
    const accessTokenHasExpired = hasAccessTokenExpired()
    if (accessTokenHasExpired) {
      const token = await refreshAccessToken()
      config.headers['Authorization'] = `Bearer ${token}`
    } else {
      config.headers['Authorization'] = `Bearer ${localStorage.getItem(
        'accessToken'
      )}`
    }
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

ApiInstance.interceptors.response.use(
  response => response,
  error => {
    const errorData = error.response?.data
    if (!!errorData && typeof errorData === 'object') {
      return Promise.reject(new Error(errorData.message ?? error.message))
    }
    return Promise.reject(error)
  }
)

export default ApiInstance
