export default {
  computed: {
    updated() {
      const time = new Date(Date.parse(this.item.updatedAt))
      const diffInSeconds = this.getTimeDiff(time)
      return diffInSeconds <= 180
    },
    created() {
      const time = new Date(Date.parse(this.item.createdAt))
      const diffInSeconds = this.getTimeDiff(time)
      return diffInSeconds <= 180
    }
  },
  methods: {
    getTimeDiff(time) {
      const now = new Date()
      const diffInSeconds = (now.getTime() - time.getTime()) / 1000
      return diffInSeconds
    }
  }
}
