import Store from '@/store/computed/storeHelpers'
import { getDocketTotals } from '../../../services/requests/dockets'
export default {
  mounted() {
    this.getDocketTotals()
  },
  computed: {
    totals: Store.getSet({ store: 'dockets', prop: 'totals' }),
    loadingTotals: Store.getSet({
      store: 'dockets',
      prop: 'loadingTotals'
    }),
    lineView: Store.getSet({ store: 'dockets', prop: 'lineView' })
  },
  methods: {
    async getDocketTotals() {
      try {
        this.loadingTotals = true
        const payload = this.buildQueryTotals()
        const result = await getDocketTotals(payload)
        if (result) {
          this.totals = result.data.result
        }
        this.loadingTotals = false
      } catch (err) {
        console.log(err)
        this.loadingTotals = false
      }
    },
    buildQueryTotals() {
      return {
        userId: this.$store.state.global.selectedUserId,
        showClosed: this.$store.state.global.showClosed,
        showAutoCreated: this.$store.state.global.showAutoCreated,
        deliveryStatus: this.$store.state.global.selectedDeliveryStatus,
        billingPeriod: this.$store.state.global.selectedBillingPeriod,
        projectId: this.$store.state.global.selectedProjectId,
        sourceId: this.$store.state.global.selectedSourceId,
        contractorId: this.$store.state.global.selectedContractorId,
        driverId: this.$store.state.global.selectedDriverId,
        vehicleId: this.$store.state.global.selectedVehicleId,
        destinationId: this.lineView
          ? this.$store.state.global.selectedDestinationId
          : undefined,
        dateRangeStart: this.$store.state.global.dateRangeStart,
        dateRangeEnd: this.$store.state.global.dateRangeEnd,
        filterCol: this.$store.state.dockets.filterCol,
        filterBy: this.$store.state.dockets.filterBy
      }
    }
  }
}
