<template>
  <v-card height="100%">
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-1">
        <span class="text-truncate">
          Edit
          {{ $store.state.global.pages.docket.titleSingular.toLowerCase() }}
          line -
          <span class="pl-1 subtitle-1">
            ID: <span class="font-weight-bold">{{ item.id }}</span>
          </span>
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      {{ message }}
      <DropdownMenu :actions="controlActions" :loading="loadingRate" />
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-form ref="editForm" @submit.prevent="edit()">
            <v-card flat class="card-outlined mb-3">
              <v-card-text class="pb-0">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      auto-select-first
                      :label="
                        `* ${$store.state.global.pages.destination.titleSingular}`
                      "
                      :prepend-icon="$store.state.global.pages.destination.icon"
                      v-model="destinationId"
                      :items="destinations"
                      item-value="id"
                      item-text="name"
                      clearable
                      :hint="destinationHint"
                      :rules="destinationRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      auto-select-first
                      :label="
                        `* ${$store.state.global.pages.itemType.titleSingular}`
                      "
                      :prepend-icon="$store.state.global.pages.itemType.icon"
                      v-model="itemTypeId"
                      :items="itemTypes"
                      item-value="id"
                      item-text="name"
                      :rules="itemTypeRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-autocomplete
                      auto-select-first
                      label="* Sell Units"
                      :prepend-icon="$store.state.global.pages.destination.icon"
                      v-model="editedItem.sellUnit"
                      :items="units"
                      :rules="sellUnitRules"
                      :disabled="!sellUnitEnabled"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      label="* Quantity"
                      prepend-icon="mdi-chevron-right"
                      v-model.number="qty"
                      @focus="openPanel('sell')"
                      :readonly="
                        editedItem.sellUnit === 'HOUR' ||
                          editedItem.sellUnit === 'TONNE'
                      "
                      :rules="qtyRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      v-if="showSell"
                      label="* Sell Price"
                      prepend-icon="mdi-chevron-right"
                      v-model.number="editedItem.sellPrice"
                      ref="sellPrice"
                      :disabled="priceDisabled"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                      v-if="showSell"
                      label="Sell Total"
                      prepend-icon="mdi-chevron-right"
                      :value="sellTotal.toFixed(2)"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-0" v-show="editedItem.costUnit">
                  <v-col class="pt-0" cols="12" sm="6" md="3">
                    <v-autocomplete
                      auto-select-first
                      label="* Cost Units"
                      :prepend-icon="$store.state.global.pages.destination.icon"
                      v-model="editedItem.costUnit"
                      :items="units"
                      :rules="rate ? costUnitRules : []"
                      :disabled="!costUnitEnabled"
                    ></v-autocomplete>
                  </v-col>
                  <v-col class="pt-0" cols="12" sm="6" md="3">
                    <v-text-field
                      label="* Cost Quantity"
                      prepend-icon="mdi-chevron-right"
                      v-model.number="costQty"
                      @focus="openPanel('cost')"
                      :readonly="
                        editedItem.costUnit === 'HOUR' ||
                          editedItem.costUnit === 'TONNE'
                      "
                      :rules="qtyRules"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pt-0" cols="12" sm="6" md="3">
                    <v-text-field
                      v-show="showCost"
                      label="Cost Price"
                      prepend-icon="mdi-chevron-right"
                      v-model.number="editedItem.costPrice"
                      ref="costPrice"
                      :disabled="priceDisabled"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pt-0" cols="12" sm="6" md="3">
                    <v-text-field
                      v-show="showCost"
                      label="Cost Total"
                      prepend-icon="mdi-chevron-right"
                      :value="costTotal.toFixed(2)"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-divider class="pa-2"></v-divider>

                <v-expansion-panels
                  v-model="massHourPanel"
                  multiple
                  flat
                  class="card-outlined"
                >
                  <v-expansion-panel v-show="showMass" active-class="mt-0">
                    <v-expansion-panel-header>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                      <h4 class="grey--text">
                        Mass
                        {{
                          destinationWeight
                            ? ` (${destinationWeight} tonne)`
                            : ''
                        }}
                      </h4>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            :label="
                              `* ${$store.state.global.pages.destination.titleSingular} weight`
                            "
                            prepend-icon="mdi-shopping_basket"
                            v-model.number="destinationWeight"
                            :rules="destinationWeightRules"
                            ref="destinationWeight"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            :label="
                              `${$store.state.global.pages.source.titleSingular} weight`
                            "
                            prepend-icon="mdi-shopping_basket"
                            v-model.number="editedItem.sourceWeight"
                            :rules="sourceWeightRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            :label="
                              `${$store.state.global.pages.vehicle.titleSingular} weight`
                            "
                            prepend-icon="mdi-shopping_basket"
                            v-model.number="editedItem.vehicleWeight"
                            :rules="vehicleWeightRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                    <v-divider v-if="showHours"></v-divider>
                  </v-expansion-panel>

                  <v-expansion-panel v-show="showHours" active-class="mt-0">
                    <v-expansion-panel-header>
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                      <h4 class="grey--text">
                        Hours
                        {{ hours ? ` (${hours.toFixed(2)} hours)` : '' }}
                      </h4>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" sm="6" md="3">
                          <DateTimePicker
                            clearable
                            v-model="editedItem.start"
                            label="Start Time"
                            :rules="showHours ? timeRules : []"
                          />
                        </v-col>

                        <v-col cols="12" sm="6" md="3">
                          <DateTimePicker
                            clearable
                            v-model="editedItem.end"
                            label="End Time"
                            :rules="showHours ? timeRules : []"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field
                            label="Breaks (-)"
                            prepend-icon="mdi-alarm_off"
                            v-model.number="editedItem.breaks"
                            :rules="breaksRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field
                            label="Travel (+)"
                            prepend-icon="mdi-add_alarm"
                            v-model.number="editedItem.travel"
                            :rules="travelRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-row>
                  <v-col cols="12" class="mt-4 pb-0">
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols="12" sm="6" class="pb-0">
                    <v-text-field
                      label="Weighbridge #"
                      prepend-icon="mdi-note"
                      v-model="editedItem.wbDocketNumber"
                      :rules="wbDocketNumberRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    <v-text-field
                      label="Notes"
                      prepend-icon="mdi-note"
                      v-model="editedItem.notes"
                      :rules="notesRules"
                    ></v-text-field>
                  </v-col>
                  <template v-if="globalSettings.useConfirmations">
                    <v-col sm="6" cols="12" class="py-0">
                      <v-autocomplete
                        auto-select-first
                        label="* GPS Status"
                        :prepend-icon="
                          $store.state.global.pages.destination.icon
                        "
                        v-model="editedItem.GPSConfirmed"
                        :items="lineConfirmations"
                      ></v-autocomplete>
                    </v-col>
                    <v-col sm="6" cols="12" class="py-0">
                      <v-text-field
                        label="GPS Notes"
                        prepend-icon="mdi-note"
                        v-model="editedItem.GPSNotes"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="6" cols="12" class="py-0">
                      <v-autocomplete
                        auto-select-first
                        label="* Tipping Status"
                        :prepend-icon="
                          $store.state.global.pages.destination.icon
                        "
                        v-model="editedItem.tippingDocketConfirmed"
                        :items="lineConfirmations"
                      ></v-autocomplete>
                    </v-col>
                    <v-col sm="6" cols="12" class="py-0">
                      <v-text-field
                        label="Tipping Notes"
                        prepend-icon="mdi-note"
                        v-model="editedItem.tippingDocketNotes"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="6" cols="12" class="pt-0">
                      <v-autocomplete
                        auto-select-first
                        label="* Mass Declaration Status"
                        :prepend-icon="
                          $store.state.global.pages.destination.icon
                        "
                        v-model="editedItem.massDeclarationConfirmed"
                        :items="lineConfirmations"
                      ></v-autocomplete>
                    </v-col>
                    <v-col sm="6" cols="12" class="pt-0">
                      <v-text-field
                        label="Mass Declaration Notes"
                        prepend-icon="mdi-note"
                        v-model="editedItem.massDeclarationNotes"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>

                <v-expansion-panels
                  multiple
                  flat
                  class="card-outlined"
                  v-if="globalSettings.useApprovedBilling && showBilling"
                >
                  <v-expansion-panel active-class="mt-0">
                    <v-expansion-panel-header>
                      <template v-slot:actions>
                        <v-chip
                          v-if="outstandingAmount"
                          color="primary"
                          class="mr-2"
                          label
                          >Outstanding: ${{ outstandingAmount }}</v-chip
                        >
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                      <h4 class="grey--text">
                        Billing information
                      </h4>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            label="Approved Amount"
                            prepend-icon="mdi-shopping_basket"
                            v-model.number="editedItem.approvedBillingAmount"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            label="Waived Amount"
                            prepend-icon="mdi-shopping_basket"
                            v-model.number="editedItem.waivedAmount"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            label="Outstanding Amount"
                            prepend-icon="mdi-shopping_basket"
                            v-model.number="outstandingAmount"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                    <v-divider v-if="showHours"></v-divider>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-dialog v-model="rateDialog" max-width="900px">
                  <RateDetails
                    v-if="!!rate && rateDialog"
                    :close="() => (rateDialog = false)"
                    :loading="loading"
                    :dialog="rateDialog"
                    :rateId="rate.id"
                    :vehicleTypeId="vehicle ? vehicle.vehicleType.id : null"
                  />
                </v-dialog>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="grey darken-1" @click="close">Cancel</v-btn>
                <v-btn text color="primary" :loading="loading" type="submit">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import DocketValidation from '@/services/validation/docket'
import DateTimePicker from '../../misc/fields/DateTimePicker'
import FormatDates from '@/services/mixins/formatDates'
import Helpers from './mixins/helpers'
import RateDetails from '@/components/rates/RateDetails'
import DropdownMenu from '../../misc/shared/DropdownMenu'

export default {
  mixins: [FormatDates, DocketValidation, Helpers],
  components: {
    DropdownMenu,
    DateTimePicker,
    RateDetails
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    sourceId: {
      type: Number,
      required: false
    },
    vehicleId: {
      type: Number,
      required: false
    },
    date: {
      type: String,
      required: false
    },
    contractor: {
      type: Object,
      required: false
    },
    item: {
      type: Object,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: false
    },
    isEditDocket: {
      type: Boolean,
      required: false,
      default: false
    },
    showBilling: {
      type: Boolean,
      required: false
    },
    source: {
      type: Object,
      required: false
    },
    vehicle: {
      type: Object,
      required: false
    }
  },
  mounted() {
    this.initPage()
  },
  data() {
    return {
      massHourPanel: [],
      rate: this.item?.rate ? Object.assign({}, this.item.rate) : null,
      qty: this.item?.qty ? this.item.qty : 0,
      costQty: this.item?.costQty ? this.item.costQty : 0,
      destinationId: this.item.destinationId,
      itemTypeId: this.item.itemTypeId,
      useAltRate: this.item.useAltRate,
      useNightRate: this.item.useNightRate,
      useOverrideCost: this.item.useOverrideCost,
      destinationWeight: this.item.destinationWeight,
      editedItem: Object.assign({}, this.item)
    }
  },
  computed: {
    unitCheck() {
      return `${this.editedItem.sellUnit} ${this.editedItem.costUnit}`
    },
    outstandingAmount() {
      const waivedAmount = this.editedItem.waivedAmount
        ? this.editedItem.waivedAmount
        : 0
      const approvedBillingAmount = this.editedItem.approvedBillingAmount
        ? this.editedItem.approvedBillingAmount
        : 0
      return Number(
        this.editedItem.sellPrice * this.qty -
          waivedAmount -
          approvedBillingAmount
      ).toFixed(2)
    }
  },
  watch: {
    unitCheck() {
      console.log('unit changed')
    },
    destinationId() {
      this.checkRate()
    },
    itemTypeId() {
      this.checkRate()
    },
    hours() {
      this.setQty()
    },
    destinationWeight() {
      this.setQty()
    },
    useAltRate() {
      this.setPricing()
      this.setQty()
    },
    useNightRate() {
      this.setPricing()
      this.setQty()
    },
    useOverrideCost() {
      this.setPricing()
      this.setQty()
    }
  },
  methods: {
    openPanel(type) {
      if (type === 'sell') {
        if (this.editedItem.sellUnit === 'TONNE') this.focusMass()
        if (this.editedItem.sellUnit === 'HOUR') this.focusTime()
      }
      if (type === 'cost') {
        if (this.editedItem.costUnit === 'TONNE') this.focusMass()
        if (this.editedItem.costUnit === 'HOUR') this.focusTime()
      }
    },
    focusMass() {
      this.massHourPanel = [0]
      setTimeout(() => this.$refs['destinationWeight'].focus(), 200)
    },
    focusTime() {
      this.massHourPanel = [1]
    },
    initPage() {
      if (
        !this.editedItem.rateId ||
        this.item.docket.sourceId != this.sourceId ||
        this.item.docket.vehicleId != this.vehicleId
      )
        this.checkRate()
    },
    edit() {
      if (!this.$refs.editForm.validate()) {
        this.snackFormError()
        return
      }
      this.editedItem.hours =
        this.isCredit && this.isEditDocket ? this.hours * -1 : this.hours
      this.editedItem.qty = this.qty
      this.editedItem.costQty = this.costQty
      this.editedItem.rate = this.rate
      this.editedItem.rateId = this.rate?.id
      this.editedItem.destinationId = this.destinationId
      this.editedItem.itemTypeId = this.itemTypeId
      this.editedItem.useAltRate = this.useAltRate
      this.editedItem.useNightRate = this.useNightRate
      this.editedItem.useOverrideCost = this.useOverrideCost
      this.editedItem.destinationWeight = this.destinationWeight
      this.editedItem.sellTotal = this.sellTotal
      this.editedItem._sellTotal = this.sellTotal.toFixed(2)
      this.action({ original: this.item, item: this.editedItem })
      this.close()
    }
  }
}
</script>
