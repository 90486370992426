<template>
  <v-form ref="form" @submit.prevent="submit()">
    <v-card>
      <v-card-text class="pb-0">
        <v-card-title class="pa-0 font-weight-regular">
          {{ title }}
        </v-card-title>

        <v-row>
          <input
            type="file"
            ref="fileUpload"
            style="display:none"
            @change="onFileChange"
          />
          <v-col cols="12" class="py-0">
            <v-text-field
              label="Select File"
              color="primary"
              prepend-icon="mdi-paperclip"
              readonly
              :value="typeof file == 'object' ? file.name : ''"
              v-model="file.name"
              @click="openFileDialog"
              :loading="loading"
            ></v-text-field>
            <span>{{ importHelp }}</span>
          </v-col>

          <v-col cols="12" class="py-0" v-if="results">
            <v-card flat class="card-outlined">
              <v-card-text class="py-0">
                <v-card-title class="px-0 pb-1 font-weight-regular">
                  Results
                </v-card-title>
              </v-card-text>
              <v-list two-line dense>
                <v-list-item two-line v-if="results.createdCount">
                  <v-list-item-content>
                    <v-list-item-title>Successfully Added!</v-list-item-title>
                    <v-list-item-subtitle>{{
                      `${results.createdCount} lines were added over ${results.createdRecords.length} document(s)!`
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <div v-if="results.errorRecords.length">
                  <v-list-group
                    v-for="(errorRecord, i) of results.errorRecords"
                    :key="i"
                  >
                    <v-list-item two-line slot="activator">
                      <v-list-item-action>
                        <v-icon
                          color="indigo lighten-3"
                          v-if="errorRecord.conflict"
                        >
                          mdi-cancel
                        </v-icon>
                        <v-icon color="red lighten-3" v-else>
                          mdi-alert-circle-outline
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title class="body-2">{{
                          route == 'docket'
                            ? `Line ${errorRecord.line}: ${errorRecord.message}`
                            : `Line ${errorRecord.line}`
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          errorRecord.conflict ? 'Duplicate!' : 'See Errors...'
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <div
                      v-for="(error, i) in errorRecord.errors"
                      :key="i"
                      class="ml-4 mr-4 mb-3 mt-2 red--text text--lighten-2"
                    >
                      {{ error }}
                    </div>
                  </v-list-group>
                </div>

                <v-divider></v-divider>
              </v-list>
              <v-card-text>
                <div class="body-2 grey--text flex-container-space-between">
                  <div v-if="results.createdCount">
                    {{ results.createdCount }} lines added
                  </div>
                  <div v-if="results.failedCount">
                    {{ results.failedCount }} lines failed
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="grey darken-1" @click="close()">Close</v-btn>
        <v-btn
          text
          color="green"
          :disabled="!file"
          :loading="loading"
          type="submit"
        >
          <v-icon left>mdi-cloud-upload</v-icon>Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import Store from '@/store/computed/storeHelpers'
import { importAllocations } from '../../../services/requests/allocations'
import AllocationLine from '../../../services/models/allocationLine'
import Allocation from '../../../services/models/allocation'
import { importDockets } from '../../../services/requests/dockets'
import DocketLine from '../../../services/models/docketLine'
import Docket from '../../../services/models/docket'

export default {
  props: {
    title: String,
    close: Function
  },
  data() {
    return {
      file: '',
      loading: false,
      results: null,
      panel: true
    }
  },
  methods: {
    openFileDialog() {
      this.$refs.fileUpload.click()
    },
    onFileChange() {
      this.file = this.$refs.fileUpload.files[0]
    },
    submit() {
      if (!this.file) {
        this.snack({ text: 'Please select a file', color: 'amber' })
        return
      }
      this.panel = false
      this.loading = true
      let formData = new FormData()
      formData.append('file', this.file)
      this.$route.name == 'docket'
        ? this.uploadDockets(formData)
        : this.uploadAllocations(formData)
    },
    async uploadAllocations(formData) {
      try {
        const result = await importAllocations(formData, 'allocation')
        this.handleSuccess(result)
      } catch (err) {
        this.snack({ text: err.response.data.message, color: 'red' })
        this.results = null
        this.loading = false
      }
    },
    async uploadDockets(formData) {
      try {
        const result = await importDockets(formData)
        this.handleSuccess(result, 'docket')
      } catch (err) {
        this.snack({ text: err.response.data.message, color: 'red' })
        this.results = null
        this.loading = false
      }
    },
    handleSuccess(result, type) {
      type = type ? type : 'allocation'
      const color = result.data.result.createdCount ? 'green' : 'yellow'
      this.snack({ text: result.data.message, color: color })

      this.results = result.data.result
      for (const record of this.results.createdRecords) {
        this.$store.commit(`${type}s/addToArrayState`, {
          prop: `${type}s`,
          value:
            type == 'allocation'
              ? new Allocation(record.result)
              : new Docket(record.result)
        })
        if (record.retrievedLines)
          for (const line of record.retrievedLines) {
            this.$store.commit(`${type}s/addToArrayState`, {
              prop: 'lines',
              value:
                type == 'allocation'
                  ? new AllocationLine(line)
                  : new DocketLine(line)
            })
          }
      }
      this.panel = true
      this.loading = false
    },
    handleError(err) {
      console.log(err)
      this.snack({ text: err.response.data.message, color: 'red' })
      this.results = err.result
      this.loading = false
    }
  },
  computed: {
    allocations: Store.getSet({ store: 'allocations', prop: 'allocations' }),
    route() {
      return this.$route.name
    },
    duplicates() {
      const duplicates = []
      if (this.results) {
        for (const errorRecord of this.results.errorRecords) {
          if (errorRecord.error.conflict) duplicates.push(errorRecord)
        }
        return duplicates
      } else {
        return duplicates
      }
    },
    importHelp() {
      const docketCols = [
        'date',
        'billingPeriod',
        'source',
        'driver',
        'vehicle',
        'vehicleType',
        'notes',
        'destination',
        'itemType',
        'lineNotes',
        'qty',
        'costQty',
        'hours',
        'destinationWeight',
        'sourceWeight',
        'vehicleWeight',
        'wbDocketNumber',
        'externalRef',
        'addressStreet1',
        'addressStreet2',
        'addressCity',
        'addressState',
        'email'
      ]
      const allocationCols = [
        'date',
        'endDate',
        'source',
        'driver',
        'vehicle',
        'vehicleType',
        'notes',
        'destination',
        'itemType',
        'lineNotes',
        'qty',
        'reference',
        'addressStreet1',
        'addressStreet2',
        'addressCity',
        'addressState',
        'email',
        'isDay'
      ]
      let str = 'Column order: '
      const cols =
        this.$route.name == 'allocation' ? allocationCols : docketCols
      for (const col of cols)
        str += ` ${
          this.$store.state.global.pages[col]
            ? this.$store.state.global.pages[col].titleSingular
            : col.charAt(0).toUpperCase() + col.slice(1)
        },`
      return str.slice(0, -1)
    }
  }
}
</script>
