import { getPricing } from '@/services/requests/rates'
import Rate from '@/services/models/rate'
import Store from '@/store/computed/storeHelpers'

export default {
  data() {
    return {
      units: ['TONNE', 'HOUR', 'EA', 'DAY'],
      lineConfirmations: ['NOT SET', 'CONFIRMED', 'REQUIRED', 'REVIEW'],
      rateDialog: false,
      qtyDialog: false,
      allowPriceChange: false,
      loadingRate: false
    }
  },
  computed: {
    destinations: Store.getSet({
      store: 'destinations',
      prop: 'destinationsLite'
    }),
    numberOfLines() {
      return this.lines?.length || this.sortedLines?.length
    },
    selectedDestination() {
      const selectedDestinationId = this.editedItem
        ? this.editedItem.destinationId
        : this.destinationId
      return this.destinations.find(({ id }) => id == selectedDestinationId)
    },
    destinationHint() {
      if (this.selectedDestination?.tippingDocketRequired) {
        return 'Tipping docket required'
      }
    },
    isCredit() {
      return this.type == 'CREDIT'
    },
    itemTypes: Store.getSet({ store: 'itemTypes', prop: 'itemTypesLite' }),
    hours() {
      const item = this.editedItem ? this.editedItem : this
      const start = this.editedItem
        ? new Date(item.start)
        : this.convertTime(item.start, new Date(this.date))
      const end =
        this.editedItem && item.end
          ? new Date(item.end)
          : this.convertTime(item.end, new Date(this.date))
      const actualHours =
        (end - start) / 1000 / 60 / 60 + item.travel - item.breaks
      if (
        Object.prototype.toString.call(start) !== '[object Date]' ||
        Object.prototype.toString.call(end) !== '[object Date]'
      )
        return null
      return Number(actualHours.toFixed(4))
    },
    isSunday() {
      return this.date
        ? new Date(this.date).getDay() == 0
        : new Date().getDay() == 0
    },
    controlActions() {
      const actions = [
        {
          action: () => (this.rateDialog = !this.rateDialog),
          text: 'Show rate',
          icon: this.$store.state.global.pages.rate.icon,
          toolTipText: 'Show rate details',
          disabled: !this.rate,
          color: 'grey'
        },
        {
          action: () => {
            this.useAltRate = false
            this.useNightRate = false
          },
          text: 'Use standard rate',
          icon: this.$store.state.global.pages.rate.icon,
          toolTipText: 'Use standard rate',
          color:
            !this.useAltRate && !this.useNightRate && this.rate
              ? 'primary'
              : 'grey',
          disabled: !this.rate
        },
        {
          action: () => {
            this.useAltRate = true
            this.useNightRate = false
          },
          text: 'Use alt rate',
          icon: 'mdi-clock-time-seven-outline',
          toolTipText: 'Use alternate rate',
          disabled: !this.rate,
          color: this.useAltRate ? 'primary' : 'grey'
        },
        {
          action: () => {
            this.useNightRate = true
            this.useAltRate = false
          },
          text: 'Use night rate',
          icon: 'mdi-weather-night',
          toolTipText: 'Use night rate',
          disabled: !this.rate,
          color: this.useNightRate ? 'primary' : 'grey'
        },
        {
          action: () => {
            this.useOverrideCost = !this.useOverrideCost
          },
          text: 'Use override from contractor',
          icon: 'mdi-currency-usd',
          toolTipText: 'Use override cost from contractor',
          disabled: !this.rate,
          color: this.useOverrideCost ? 'primary' : 'grey'
        }
      ]
      return actions.reverse()
    },
    message() {
      let message = ''
      if (this.rate) {
        message += `Rate ID: ${this.rate.id}`
        if (this.useNightRate) message += ` [Night]`
        if (this.useAltRate) message += ` [Alt]`
        if (this.useOverrideCost) message += ` [Override]`
        if (this.isSunday) message += ' [Sunday]'
      }
      if (!this.rate && this.globalSettings.useRate)
        message += 'No active rate!'
      return message
    },
    showHours() {
      const item = this.editedItem ? this.editedItem : this
      return item.costUnit == 'HOUR' || item.sellUnit == 'HOUR'
    },
    showMass() {
      const item = this.editedItem ? this.editedItem : this
      return (
        item.costUnit == 'TONNE' ||
        item.sellUnit == 'TONNE' ||
        !!this.rate?.exportAmount
      )
    },
    sellTotal() {
      const item = this.editedItem ? this.editedItem : this
      return this.qty * item.sellPrice
    },
    costTotal() {
      const item = this.editedItem ? this.editedItem : this
      return this.costQty * item.costPrice
    },
    disableCostQty() {
      // const item = this.editedItem ? this.editedItem : this
      // return item.costUnit == 'TONNE' || item.costUnit == 'HOUR'
      return false
    },
    isManager() {
      return this.$store.state.user.userAuthClass.isManager
    },
    isAdministrator() {
      return this.$store.state.user.userAuthClass.name == 'Administrator'
    },
    showCost() {
      return this.$store.state.user.userAuthClass.showCost
    },
    showSell() {
      return this.$store.state.user.userAuthClass.showSell
    },
    priceDisabled() {
      return !!this.rate
    },
    costUnitEnabled() {
      return !this.rate
    },
    sellUnitEnabled() {
      return !this.rate && !this.loadingRate
    },
    calculateQty() {
      return this.showHours || this.showMass
    }
  },
  methods: {
    setQty() {
      const item = this.editedItem ? this.editedItem : this
      if (item.sellUnit == 'HOUR')
        this.qty = this.hours ? Number(this.hours.toFixed(4)) : 0
      if (item.costUnit == 'HOUR')
        this.costQty = this.hours ? Number(this.hours.toFixed(4)) : 0
      if (item.sellUnit == 'TONNE')
        this.qty = this.destinationWeight ? this.destinationWeight : 0
      if (item.costUnit == 'TONNE' && this.destinationWeight != null)
        this.costQty = this.destinationWeight ? this.destinationWeight : 0
      // if (this.isCredit && !this.adjustBill) this.qty = 0
      // if (this.isCredit && !this.adjustRcti) this.costQty = 0
    },
    clearValues() {
      const item = this.editedItem ? this.editedItem : this
      if (item.sellUnit != 'TONNE' && item.costUnit != 'TONNE') {
        this.destinationWeight = 0
        item.sourceWeight = 0
        item.vehicleWeight = 0
      }
      if (item.sellUnit != 'HOUR' && item.costUnit != 'HOUR') {
        item.start = null
        item.end = null
        item.breaks = 0
        item.travel = 0
      }
    },
    async checkRate() {
      this.loadingRate = true
      const projectId = this.source?.projectId
      const destinationId = this.destinationId
      const itemTypeId = this.itemTypeId
      const sourceId = this.sourceId
      const vehicleId = this.vehicleId

      if (
        !destinationId ||
        !itemTypeId ||
        !sourceId ||
        !vehicleId ||
        !projectId
      ) {
        this.rate = null
        this.loadingRate = false
        return
      }
      const payload = {
        projectId,
        destinationId,
        itemTypeId,
        sourceId,
        vehicleId
      }
      try {
        const result = await getPricing(payload)
        if (result) {
          this.rate = new Rate(result.data.result)
        }
        this.loadingRate = false
      } catch (err) {
        this.snack({ text: `No rate found`, color: 'amber' })
        this.loadingRate = false
        this.rate = null
        console.log(err)
      }
    },
    setPricing() {
      const item = this.editedItem ? this.editedItem : this
      if (!this.rate) return
      const setPrices = (sellUnit, sellPrice, costUnit, costPrice) => {
        item.sellUnit = sellUnit
        item.sellPrice = sellPrice
        item.costUnit = costUnit
        item.costPrice = this.useOverrideCost
          ? this.vehicle.contractor.override
          : costPrice
      }
      const hasSundayPrice =
        !!this.rate.sellPriceSunday || !!this.rate.costPriceSunday
      const hasSundayAltPrice =
        !!this.rate.sellPriceAltSunday || !!this.rate.costPriceAltSunday
      if (this.useNightRate) {
        setPrices(
          this.rate.sellUnitNight,
          this.rate.sellPriceNight,
          this.rate.costUnitNight,
          this.rate.costPriceNight
        )
      } else if (this.useAltRate) {
        setPrices(
          this.rate.sellUnitAlt,
          this.rate.sellPriceAlt,
          this.rate.costUnitAlt,
          this.rate.costPriceAlt
        )
        if (this.isSunday && hasSundayAltPrice) {
          setPrices(
            this.rate.sellUnitAlt,
            this.rate.sellPriceAltSunday,
            this.rate.costUnitAlt,
            this.rate.costPriceAltSunday
          )
        }
      } else {
        if (this.isSunday && hasSundayPrice) {
          setPrices(
            this.rate.sellUnitSunday,
            this.rate.sellPriceSunday,
            this.rate.costUnitSunday,
            this.rate.costPriceSunday
          )
        } else {
          setPrices(
            this.rate.sellUnit,
            this.rate.sellPrice,
            this.rate.costUnit,
            this.rate.costPrice
          )
        }
      }
      const useTripsAltRate =
        Number(this.rate.altRateTrips) > 0 &&
        Number(this.numberOfLines + 1) > Number(this.rate.altRateTrips)
      if (useTripsAltRate) {
        item.useNightRate = false
        item.useAltRate = true
        if (!item.notes?.includes('triggered'))
          item.notes
            ? (item.notes += ` [Alternate rate triggered: Line ${this
                .numberOfLines + 1}]`)
            : (item.notes = `[Alternate rate triggered: Line ${this
                .numberOfLines + 1}]`)
        setPrices(
          this.rate.sellUnitAlt,
          this.rate.sellPriceAlt,
          this.rate.costUnitAlt,
          this.rate.costPriceAlt
        )
        if (this.isSunday && hasSundayAltPrice) {
          setPrices(
            this.rate.sellUnitAlt,
            this.rate.sellPriceAltSunday,
            this.rate.costUnitAlt,
            this.rate.costPriceAltSunday
          )
        }
      }
    },
    showQtyDialog() {
      const item = this.editedItem ? this.editedItem : this
      if (
        (item.sellUnit == 'TONNE' ||
          item.sellUnit == 'HOUR' ||
          item.costUnit == 'TONNE' ||
          item.costUnit == 'HOUR') &&
        !item.qty
      ) {
        this.focusRef('sellPrice')
        this.clearTimes = true
        this.qtyDialog = true
        this.$nextTick(() => {
          item.sellUnit == 'TONNE'
            ? this.focusRef('destinationWeight')
            : this.focusRef('sellPrice')
        })
      } else {
        this.qtyDialog = false
      }
    },
    updateTimeline() {
      const startIndex = this.timesList.indexOf(this.start)
      const endIndex = this.timesList.indexOf(this.end)
      if (startIndex != -1 && endIndex != -1)
        this.selectedRange = [startIndex, endIndex]
    }
  },
  watch: {
    rate() {
      const item = this.editedItem ? this.editedItem : this
      if (this.rate) {
        this.setPricing()
        this.setQty()
      } else {
        item.sellUnit = null
        item.sellPrice = 0
        item.costUnit = null
        item.costPrice = 0
      }
    }
  }
}
