<template>
  <v-card>
    <v-card-text class="pb-0">
      <v-card-title
        class="d-flex justify-space-between font-weight-regular px-0"
      >
        Populate by Run
      </v-card-title>
      <v-row>
        <v-col cols="12">
          Select a range of run numbers to import destination
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
            auto-select-first
            :items="runs"
            label="* Starting run number"
            item-text="runCode"
            v-model="runCodeStart"
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
            auto-select-first
            :items="runs"
            label="* Ending run number"
            item-text="runCode"
            v-model="runCodeEnd"
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            auto-select-first
            :items="itemTypes"
            :label="`* ${$store.state.global.pages.itemType.titleSingular}`"
            item-text="name"
            v-model="itemType"
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="text-right" v-if="destinationCount"
          >{{ destinationCount }} destination(s) found</v-col
        >
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text color="grey darken-1" @click="close">Cancel</v-btn>
      <v-btn
        :disabled="!destinationCount || !itemType"
        color="primary"
        @click="populate"
        outlined
      >
        ADD {{ destinationCount }} NEW LINE(S)
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import getRuns from '../../../services/mixins/getMasterFiles/getRuns'
export default {
  mixins: [getRuns],
  props: {
    lines: {
      type: Array,
      required: true
    },
    itemTypes: {
      type: Array,
      required: true
    },
    destinationCount: {
      type: Number
    },
    populateByRun: {
      type: Function,
      required: true
    },
    getDestinationsByRun: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      runCodeStart: null,
      runCodeEnd: null,
      runDestinations: null,
      itemType: null
    }
  },
  watch: {
    runCodeStart() {
      this.getDestinationsByRun(this.runCodeStart, this.runCodeEnd)
    },
    runCodeEnd() {
      this.getDestinationsByRun(this.runCodeStart, this.runCodeEnd)
    }
  },
  methods: {
    async populate() {
      this.populateByRun(this.runCodeStart, this.runCodeEnd, this.itemType)
      this.runCodeStart = null
      this.runCodeEnd = null
      this.itemType = null
      this.close()
    }
  }
}
</script>
