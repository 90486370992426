import Api from '@/services/api.js'

export const addRate = payload => {
  return Api.post('rates/', payload)
}
export const getRate = id => {
  return Api.get(`rates/getRateById/${id}`)
}
export const getRates = payload => {
  return Api.post('rates/get', payload)
}
// Not in use
export const getRatesLite = () => {
  return Api.post('rates/')
}
export const getRatesByIds = payload => {
  return Api.post('rates/byIds', payload)
}
export const getPricing = payload => {
  return Api.post('rates/getPricing', payload)
}
export const updateRate = (id, payload) => {
  return Api.put(`rates/${id}`, payload)
}
export const deleteRate = id => {
  return Api.delete(`rates/${id}`)
}
