<template>
  <v-card flat class="card-outlined">
    <v-card-text class="pb-0 d-flex justify-space-between align-center">
      <v-row>
        <v-col cols="12" sm="10" class="py-0">
          <MultiActions
            page="bills"
            :selectedCount="selectedCount"
            :fieldsToChange="actionFields"
            :exportOptions="exportOptions"
          />
        </v-col>
        <v-col cols="12" sm="2" class="py-0 d-flex justify-end align-center">
          <v-tooltip left transition="slide-x-transition">
            <template v-slot:activator="{ on }">
              <div v-on="on" class="py-3">
                <v-btn
                  text
                  icon
                  color="grey"
                  @click="chartDialog = !chartDialog"
                  class="mr-2"
                >
                  <v-icon>mdi-chart-box</v-icon>
                </v-btn>
              </div>
            </template>
            <span>View charts</span>
          </v-tooltip>
          <ColumnSelector
            :defaultHeaders="defaultHeaders"
            :possibleHeaders="possibleHeaders"
            toolTipText="Column selector"
            :onChanged="val => (defaultHeaders = val)"
          />
        </v-col>
        <SelectAlert
          :selected="selected"
          :selectedAll="selectedAll"
          :totalRecordCount="count"
          :selectAll="() => (selectedAll = true)"
          :unselectAll="() => ((selectedAll = false), (selected = []))"
        />
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="bills"
      :loading="loadingBills"
      :options.sync="pagination"
      :server-items-length="count"
      mobile-breakpoint="0"
      :footer-props="{
        'items-per-page-options': [10, 20, 40, 50],
        showFirstLastPage: true
      }"
      v-model="selected"
      show-select
    >
      <template v-slot:no-data>
        No matching records found
      </template>
      <template v-slot:loading>
        Loading records...
      </template>
      <template v-slot:item="{ item, isSelected }">
        <Row
          :headers="headers"
          :item="item"
          :key="item.id"
          showEdit
          :value="isSelected"
          @input="toggleSelected"
          select
          editDialogFullscreen
          detailsDialogFullscreen
          defaultDialog="detailsDialog"
        >
          <template v-slot:details="{ dialog, close, openEditDialog }">
            <BillDetails
              v-if="dialog"
              :dialog="dialog"
              :item="item"
              :loading="loading"
              :close="close"
              :openEditDialog="openEditDialog"
            />
          </template>
          <template v-slot:edit="{ dialog, close }">
            <BillEdit
              v-if="dialog"
              :dialog="dialog"
              :item="item"
              :loading="loading"
              :action="editBill"
              :close="close"
            />
          </template>
          <template v-slot:delete="{ dialog, close }">
            <DeleteDialog
              :dialog="dialog"
              :page="titleSingular"
              :subject="item"
              :action="deleteBill"
              :close="close"
            />
          </template>
        </Row>
      </template>
      <template
        v-slot:footer[`page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        <div class="text-center mt-1">
          <span v-if="itemsLength">
            {{ `${pageStart}-${pageStop} of ${itemsLength}` }}
          </span>
          <span v-else> - </span>
          <v-progress-linear
            v-if="loadingBills && itemsLength"
            size="15"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <div v-else style="height:5px"></div>
        </div>
      </template>
    </v-data-table>
    <v-dialog
      v-model="chartDialog"
      @click:outside="() => (chartDialog = false)"
      @keydown.esc="() => (chartDialog = false)"
      fullscreen
    >
      <chart :close="() => (chartDialog = false)" />
    </v-dialog>
  </v-card>
</template>

<script>
import { updateBill, deleteBill } from '../../services/requests/bills'
import {
  createXeroBill,
  createQuickbooksBill,
  createMyobBill
} from '../../services/requests/accounting'
import getBillsMixin from '../../services/mixins/getTransactionFiles/getBills'
import Store from '@/store/computed/storeHelpers'
import Headers from './mixins/billHeaders'
import HeaderFilters from '@/services/mixins/headerFilters'
import Bill from '../../services/models/bill'
import BillExport from './mixins/billExport'

import Row from '../misc/row/Row'
import BillEdit from './BillEdit'
import BillDetails from './BillDetails'
import MultiActions from '../misc/shared/MultiActions'
import ColumnSelector from '../misc/shared/ColumnSelector'
import SelectAlert from '../misc/shared/SelectAlert'
import DeleteDialog from '../misc/DeleteDialog'
import MultipleRecords from '../../services/mixins/multipleRecords'
import Chart from '../misc/shared/Chart'
import { debounce } from 'lodash'

export default {
  mixins: [getBillsMixin, Headers, HeaderFilters, MultipleRecords, BillExport],
  components: {
    Chart,
    Row,
    BillDetails,
    BillEdit,
    MultiActions,
    ColumnSelector,
    SelectAlert,
    DeleteDialog
  },
  props: {
    page: String
  },
  mounted() {
    if (this.$route.query.ids) {
      this.showCreated = true
    } else {
      this.showCreated = false
    }
    this.getBills()
  },
  beforeDestroy() {
    this.bills = []
    this.selected = []
  },
  data() {
    return {
      chartDialog: false,
      loading: false,
      count: 0
    }
  },
  computed: {
    actionFields() {
      const authLimitedActions = [
        {
          text: `${this.$store.state.global.pages.bill.titleSingular} Status`,
          icon: 'mdi-information',
          color: 'green',
          value: {
            value: null,
            text: `${this.$store.state.global.pages.bill.titleSingular} Status`,
            label: 'update'
          },
          types: this.getTypes('status', 'internalNotes', 'mdi-information')
        },
        {
          text: `Create ${this.$store.state.settings.settings
            .accountingSystem || ''} invoices`,
          icon: 'mdi-file-export',
          color: 'grey',
          value: {
            value: null,
            text: `Create ${this.$store.state.settings.settings
              .accountingSystem || ''} invoices`,
            label: 'import'
          },
          action: async () => this.createInvoices()
        },
        {
          text: 'Delete selected',
          icon: 'mdi-delete',
          color: 'red',
          value: { value: null, text: 'Delete Selected', label: 'delete' },
          action: async () => {
            await this.deleteMultipleRecords({
              ids: this.selected.map(({ id }) => id),
              table: 'bills',
              query: this.selectedAll ? this.buildQuery() : null
            })
          }
        }
      ]

      let actions = [
        {
          text: 'Export selected',
          icon: 'mdi-file-export',
          color: 'grey',
          value: { value: null, text: 'Export Selected', label: 'export' }
        },
        {
          text: 'Print selected',
          icon: 'mdi-file-pdf-box',
          color: 'grey',
          value: { value: null, text: 'Print selected', label: 'print' }
        }
      ]
      if (this.userAuthClass) actions = [...actions, ...authLimitedActions]
      return actions
    },
    bills: Store.getSet({ store: 'bills', prop: 'bills' }),
    selected: Store.getSet({ store: 'bills', prop: 'selected' }),
    showClosed: Store.getSet({ store: 'global', prop: 'showClosed' }),
    selectedUserId: Store.getSet({
      store: 'global',
      prop: 'selectedUserId'
    }),
    selectedProjectId: Store.getSet({
      store: 'global',
      prop: 'selectedProjectId'
    }),
    selectedSourceId: Store.getSet({
      store: 'global',
      prop: 'selectedSourceId'
    }),
    dateRangeStart: Store.getSet({ store: 'global', prop: 'dateRangeStart' }),
    dateRangeEnd: Store.getSet({ store: 'global', prop: 'dateRangeEnd' }),
    filterBy: Store.getSet({ store: 'bills', prop: 'filterBy' }),
    filterCol: Store.getSet({ store: 'bills', prop: 'filterCol' }),
    billingPeriod: Store.getSet({
      store: 'global',
      prop: 'selectedBillingPeriod'
    }),
    selectedAll: Store.getSet({ store: 'bills', prop: 'selectedAll' }),
    selectedCount() {
      return this.selectedAll ? this.count : this.selected.length
    },
    headers() {
      const actions = {
        text: 'Actions',
        align: 'center',
        value: '',
        sortable: false,
        colData: '',
        type: 'action'
      }

      const tempArray = this.filterHeaders()
      tempArray.push(actions)
      return tempArray
    },
    accounting() {
      return this.globalSettings.accountingSystem
    }
  },
  watch: {
    bills() {
      this.selected = []
      this.selectedAll = false
    },
    showClosed() {
      this.getBillFromPage1()
    },
    selectedUserId() {
      this.getBillFromPage1()
    },
    selectedProjectId() {
      this.getBillFromPage1()
    },
    selectedSourceId() {
      this.getBillFromPage1()
    },
    dateRangeStart() {
      this.getBillFromPage1()
    },
    dateRangeEnd() {
      this.getBillFromPage1()
    },
    filterCol() {
      this.getBillFromPage1()
    },
    filterBy: debounce(function() {
      this.getBillFromPage1()
    }, 300),

    billingPeriod() {
      this.getBillFromPage1()
    }
  },
  methods: {
    getTypes(field, notesField, icon) {
      const typeStatuses = [
        { text: 'Entered', value: 'ENTERED', color: 'blue' },
        { text: 'Approved', value: 'APPROVED', color: 'green' },
        { text: 'Review', value: 'REVIEW', color: 'amber' },
        { text: 'Commited', value: 'COMMITED', color: 'green' },
        { text: 'Unconfirm', value: 'NOT SET', color: 'grey' }
      ]
      const types = []
      for (const status of typeStatuses) {
        const payload = {
          table: 'bills',
          field,
          notesField,
          value: status.value,
          query: this.selectedAll ? this.buildQuery() : null,
          model: Bill,
          prop: 'bills',
          store: 'bills'
        }
        types.push({
          text: status.text,
          icon,
          color: status.color,
          value: { value: null, text: status.text, label: 'update' },
          action: async notes =>
            await this.updateMultipleRecords({
              ...payload,
              items: this.selected,
              notes
            })
        })
      }
      return types
    },
    async getBillFromPage1() {
      this.pagination.page = 1
      await this.getBills()
    },
    toggleSelected(val) {
      if (val.value) {
        this.selected.push(val.object)
      } else {
        const index = this.selected.indexOf(val.object)
        this.selected.splice(index, 1)
      }
    },
    async editBill(bill) {
      try {
        this.loading = true
        const payload = {
          date: bill.date,
          billingPeriod: bill.billingPeriod,
          abn: bill.abn,
          externalRef: bill.externalRef,
          transactionId: bill.transactionId,
          internalNotes: bill.internalNotes,
          invoiceNotes: bill.invoiceNotes,
          statDecConfirmed: bill.statDecConfirmed,
          statDecNotes: bill.statDecNotes,
          status: bill.status,
          type: bill.type
        }
        const result = await updateBill(bill.id, payload)
        if (result) {
          this.$store.commit('bills/updateArrayStateById', {
            prop: 'bills',
            editedValue: new Bill(result.data.result)
          })
          this.snackUpdated()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    async deleteBill(bill) {
      try {
        this.loading = true
        const result = await deleteBill(bill.id)
        if (result) {
          this.$store.commit('bills/deleteFromArrayStateByValue', {
            prop: 'bills',
            value: bill
          })
          this.snackDeleted()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    async createInvoices() {
      try {
        if (!this.accounting)
          return this.snack({
            text: 'No accounting software selected in global settings',
            color: 'amber'
          })
        const route = () => {
          if (this.accounting === 'Xero') return createXeroBill
          if (this.accounting === 'Quickbooks') return createQuickbooksBill
          if (this.accounting === 'MYOB') return createMyobBill
        }
        const payload = {
          ids: this.selected.map(({ id }) => id),
          query: this.selectedAll ? this.buildQuery() : null
        }
        const result = await route()(payload)
        if (!result?.data?.result?.length) {
          return this.snack({
            text: 'Error creating invoices',
            color: 'amber'
          })
        }
        for (const bill of result.data.result) {
          this.$store.commit('bills/updateArrayStateById', {
            prop: 'bills',
            editedValue: new Bill(bill)
          })
        }
        this.snack({
          text: result.data.message,
          color: 'green'
        })
      } catch (err) {
        this.snack({
          text: err.response.data.message,
          color: 'amber'
        })
        console.log(err)
      }
    }
  }
}
</script>
