import UserAuthClassValidation from '../../validation/user'
export default {
  mixins: [UserAuthClassValidation],
  computed: {
    fields() {
      return [
        {
          label: '* Name',
          prop: 'name',
          value: null,
          rules: this.nameRules
        },
        {
          title: 'Master files'
        },
        {
          label: `${this.$store.state.global.pages.user.titleSingular} Read`,
          prop: 'userRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.user.titleSingular} Write`,
          prop: 'userWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.user.titleSingular} Auth Class Read`,
          prop: 'userAuthClassRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.user.titleSingular} Auth Class Write`,
          prop: 'userAuthClassWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: 'Rate Read',
          prop: 'rateRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: 'Rate Write',
          prop: 'rateWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.project.titleSingular} Read`,
          prop: 'projectRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.project.titleSingular} Write`,
          prop: 'projectWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.source.titleSingular} Read`,
          prop: 'sourceRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.source.titleSingular} Write`,
          prop: 'sourceWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.contractor.titleSingular} Read`,
          prop: 'contractorRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.contractor.titleSingular} Write`,
          prop: 'contractorWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.vehicle.titleSingular} Read`,
          prop: 'vehicleRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.vehicle.titleSingular} Write`,
          prop: 'vehicleWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.vehicle.titleSingular} Type Read`,
          prop: 'vehicleTypeRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.vehicle.titleSingular} Type Write`,
          prop: 'vehicleTypeWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.driver.titleSingular} Read`,
          prop: 'driverRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.driver.titleSingular} Write`,
          prop: 'driverWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.destination.titleSingular} Read`,
          prop: 'destinationRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.destination.titleSingular} Write`,
          prop: 'destinationWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.itemType.titleSingular} Read`,
          prop: 'itemTypeRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.itemType.titleSingular} Write`,
          prop: 'itemTypeWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: 'Settings Read',
          prop: 'settingsRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: 'Settings Write',
          prop: 'settingsWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.policy.titleSingular} Read`,
          prop: 'policyRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.policy.titleSingular} Write`,
          prop: 'policyWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.compliance.titleSingular} Read`,
          prop: 'complianceRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.compliance.titleSingular} Write`,
          prop: 'complianceWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: 'Map Read',
          prop: 'mapRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          title: 'Transactions'
        },
        {
          label: `${this.$store.state.global.pages.allocation.titleSingular} Read`,
          prop: 'allocationRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.allocation.titleSingular} Write`,
          prop: 'allocationWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.plan.titleSingular} Read`,
          prop: 'planRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.plan.titleSingular} Write`,
          prop: 'planWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.docket.titleSingular} Read`,
          prop: 'docketRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.docket.titleSingular} Write`,
          prop: 'docketWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.rcti.titleSingular} Read`,
          prop: 'rctiRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.rcti.titleSingular} Write`,
          prop: 'rctiWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.bill.titleSingular} Read`,
          prop: 'billRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.bill.titleSingular} Write`,
          prop: 'billWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        // {
        //   label: `${this.$store.state.global.pages.approvedBillLine.titleSingular} Read`,
        //   prop: 'approvedBillLineRead',
        //   value: false,
        //   sm: '6',
        //   type: 'boolean'
        // },
        // {
        //   label: `${this.$store.state.global.pages.approvedBillLine.titleSingular} Write`,
        //   prop: 'approvedBillLineWrite',
        //   value: false,
        //   sm: '6',
        //   type: 'boolean'
        // },
        {
          label: `${this.$store.state.global.pages.pod.titleSingular} Read`,
          prop: 'podRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.pod.titleSingular} Write`,
          prop: 'podWrite',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: 'Audit Read',
          prop: 'auditRead',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          title: 'Other settings'
        },
        // {
        //   label: 'Edit Pricing',
        //   prop: 'editPricing',
        //   value: false,
        //   sm: '6',
        //   type: 'boolean'
        // },
        {
          label: 'Show Cost',
          prop: 'showCost',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: 'Show Sell',
          prop: 'showSell',
          value: false,
          sm: '6',
          type: 'boolean'
        }
      ]
    }
  }
}
