<template>
  <div>
    <v-card flat class="card-outlined">
      <v-card-text>
        <CardHeader
          :title="
            `${$store.state.global.pages.allocation.titleSingular} Line Entry`
          "
          :icon="$store.state.global.pages.allocation.icon"
        />
        <v-form ref="addForm" @submit.prevent="add()">
          <v-row>
            <v-col cols="12" sm="3" v-if="false">
              <v-autocomplete
                auto-select-first
                label="* Type"
                v-model="type"
                :items="types"
                item-value="id"
                item-text="name"
                :rules="typeRules"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-autocomplete
                auto-select-first
                :label="
                  `* ${$store.state.global.pages.destination.titleSingular}`
                "
                :prepend-icon="$store.state.global.pages.destination.icon"
                v-model="destinationId"
                :items="filteredDestinations"
                item-value="id"
                item-text="destinationName_number"
                clearable
                :rules="destinationRules"
                ref="destinationId"
                :disabled="isVoid"
              ></v-autocomplete>
              <EditDeliveryAddress
                v-if="allowAutocomplete"
                :destinationId="destinationId"
                :destinations="destinations"
                :editAddress="editAddress"
                @toggleAddress="() => (editAddress = !editAddress)"
                @newAddress="newAddress"
              ></EditDeliveryAddress>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-autocomplete
                auto-select-first
                :label="`* ${$store.state.global.pages.itemType.titleSingular}`"
                :prepend-icon="$store.state.global.pages.itemType.icon"
                v-model="itemTypeId"
                :items="filteredItemTypes"
                item-value="id"
                item-text="name"
                :rules="itemTypeRules"
                :disabled="isVoid"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                label="Qty"
                type="number"
                prepend-icon="mdi-note"
                v-model.number="qty"
                :rules="qtyRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                label="Notes"
                prepend-icon="mdi-note"
                v-model="notes"
                :rules="notesRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-expand-transition>
                <v-row v-show="destinationId && itemTypeId && !isVoid">
                  <v-col class="py-0 d-flex justify-end">
                    <v-btn
                      :disabled="isVoid"
                      text
                      color="primary"
                      type="submit"
                      class="mb-5"
                    >
                      Add
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expand-transition>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-container fluid class="px-0">
      <v-row>
        <v-expand-transition>
          <v-col cols="12">
            <AllocationLineTable
              :headers="lineHeaders"
              :items="lines"
              :onEdit="onEdit"
              :onDelete="onDelete"
              :loading="loading"
              :loadingLines="loadingLines"
              showEdit
              hideDetails
            />
          </v-col>
        </v-expand-transition>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Store from '../../../store/computed/storeHelpers'
import AllocationValidation from '@/services/validation/allocation'
import EditDeliveryAddress from '../../misc/editDeliveryAddress'
import FilterItemDestination from '../../../services/mixins/filterItemDestination'

import CardHeader from '../../misc/shared/CardHeader'
import AllocationLineTable from '@/components/allocations/lines/AllocationLineTable'
export default {
  mixins: [AllocationValidation, FilterItemDestination],
  components: {
    CardHeader,
    AllocationLineTable,
    EditDeliveryAddress
  },
  props: {
    lines: {
      type: Array,
      required: true
    },
    onAdd: {
      type: Function,
      required: true
    },
    onEdit: {
      type: Function,
      required: true
    },
    onDelete: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    loadingLines: {
      type: Boolean,
      required: false
    },
    isEditAllocation: {
      type: Boolean,
      required: false,
      default: false
    },
    lineHeaders: {
      type: Array,
      required: true
    },
    source: {
      type: Object,
      required: false
    },
    isVoid: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      type: 'DELIVERY',
      destinationId: null,
      itemTypeId: null,
      notes: null,
      qty: 1,
      types: ['DELIVERY'],
      editAddress: false,
      currentDeliveryAddress: null,
      allowAutocomplete: process.env.VUE_APP_GOOGLE_MAPS_API_KEY != ''
    }
  },
  computed: {
    destinations: Store.getSet({
      store: 'destinations',
      prop: 'destinationsLite'
    }),
    itemTypes: Store.getSet({ store: 'itemTypes', prop: 'itemTypesLite' }),
    currentDestination() {
      return this.destinations.filter(item => item.id == this.destinationId)[0]
    }
  },
  methods: {
    add() {
      if (!this.$refs.addForm.validate()) {
        this.snackFormError()
        return
      }
      const line = {
        id: this.lines.length + 1,
        type: this.type,
        destinationId: this.destinationId,
        itemTypeId: this.itemTypeId,
        qty: this.qty,
        notes: this.notes,
        addressStreet1: this.currentDeliveryAddress?.addressStreet1,
        addressStreet2: this.currentDeliveryAddress?.addressStreet2,
        addressCity: this.currentDeliveryAddress?.addressCity,
        addressState: this.currentDeliveryAddress?.addressState
      }
      this.onAdd(line)
      this.focusRef('destinationId')
      this.clear()
    },
    clear() {
      this.type = 'DELIVERY'
      this.notes = null
      if (this.isEditAllocation) {
        this.destinationId = null
        this.itemTypeId = null
      }
    },
    newAddress(address) {
      this.currentDeliveryAddress = address
      this.editAddress = false
    },
    focusRef(ref) {
      if (this.$refs[ref]) this.$refs[ref].focus()
    }
  },
  watch: {
    destinationId() {
      if (this.currentDestination) {
        this.currentDeliveryAddress = {
          addressStreet1: this.currentDestination.addressStreet1,
          addressStreet2: this.currentDestination.addressStreet2,
          addressCity: this.currentDestination.addressCity,
          addressState: this.currentDestination.addressState
        }
      }
    }
  }
}
</script>
