<template>
  <v-navigation-drawer
    persistent
    temporary
    :value="value"
    @input="drawerInput"
    fixed
    app
    right
  >
    <div>
      <v-list subheader two-line>
        <v-tooltip>
          <template v-slot:activator="{ on }">
            <v-subheader v-on="on">{{
              $route.name == 'email' ? 'Options' : 'Filters and Settings'
            }}</v-subheader>
          </template>
          <span>
            {{
              $route.name == 'email'
                ? 'Here you can quickly add Sub-contractors who have worked on projects or sites'
                : 'These settings filter the data that is returned from the server. Data can be sorted using tools on the main page.'
            }}
          </span>
        </v-tooltip>

        <v-list-item v-if="showBillingPeriod">
          <v-autocomplete
            auto-select-first
            prepend-icon="mdi-calendar-blank"
            label="Billing Period"
            color="primary"
            :items="$store.state.billingPeriods.allBillingPeriods"
            item-value="id"
            v-model="selectedBillingPeriod"
            clearable
            @click:clear="() => $nextTick(() => (selectedBillingPeriod = null))"
          ></v-autocomplete>
        </v-list-item>

        <v-list-item v-if="showProjects">
          <v-autocomplete
            auto-select-first
            :prepend-icon="$store.state.global.pages.project.icon"
            :label="$store.state.global.pages.project.titleSingular"
            color="primary"
            :items="projects"
            item-text="name"
            item-value="id"
            v-model="selectedProjectId"
            clearable
            @click:clear="() => $nextTick(() => (selectedProjectId = null))"
          >
            <template v-slot:item="data">
              <FilterAutoComplete
                :name="data.item.name"
                :active="data.item.active"
              />
            </template>
          </v-autocomplete>
        </v-list-item>

        <v-list-item v-if="showSources">
          <v-autocomplete
            auto-select-first
            :prepend-icon="$store.state.global.pages.source.icon"
            :label="$store.state.global.pages.source.titleSingular"
            color="primary"
            :items="filteredSources"
            item-text="name"
            item-value="id"
            v-model="selectedSourceId"
            clearable
            @click:clear="() => $nextTick(() => (selectedSourceId = null))"
          >
            <template v-slot:item="data">
              <FilterAutoComplete
                :name="data.item.sourceName_projectName"
                :active="data.item.project.active"
              />
            </template>
          </v-autocomplete>
        </v-list-item>

        <v-list-item v-if="podTypes">
          <v-autocomplete
            auto-select-first
            prepend-icon="mdi-calendar-blank"
            label="Pod Type"
            color="Primary"
            :items="podTypes"
            item-value="value"
            item-text="name"
            v-model="selectedPodType"
            clearable
            multiple
            @click:clear="() => $nextTick(() => (selectedPodType = null))"
          ></v-autocomplete>
        </v-list-item>
        <v-list-item v-if="showContractors">
          <v-autocomplete
            auto-select-first
            :prepend-icon="$store.state.global.pages.contractor.icon"
            :label="$store.state.global.pages.contractor.titleSingular"
            color="primary"
            :items="contractors"
            item-text="name"
            item-value="id"
            v-model="selectedContractorId"
            clearable
            @click:clear="() => $nextTick(() => (selectedContractorId = null))"
            @change="contractorOnChanged"
          >
            <template v-slot:item="data">
              <FilterAutoComplete
                :name="data.item.name"
                :active="data.item.active"
              />
            </template>
          </v-autocomplete>
        </v-list-item>

        <v-list-item v-if="showVehicleTypes">
          <v-autocomplete
            auto-select-first
            :prepend-icon="$store.state.global.pages.vehicleType.icon"
            :label="$store.state.global.pages.vehicleType.titleSingular"
            color="primary"
            :items="vehicleTypes"
            item-text="name"
            item-value="id"
            v-model="selectedVehicleTypeId"
            clearable
            @click:clear="() => $nextTick(() => (selectedVehicleTypeId = null))"
          ></v-autocomplete>
        </v-list-item>
        <v-list-item v-if="showVehicles">
          <v-autocomplete
            auto-select-first
            :prepend-icon="$store.state.global.pages.vehicle.icon"
            :label="$store.state.global.pages.vehicle.titleSingular"
            color="primary"
            :items="filteredVehicles"
            item-text="vehicleName_contractorName_vehicleType"
            item-value="id"
            v-model="selectedVehicleId"
            clearable
            @click:clear="() => $nextTick(() => (selectedVehicleId = null))"
            @change="vehicleOnChanged"
          >
            <template v-slot:item="data">
              <FilterAutoComplete
                :name="data.item.vehicleName_contractorName_vehicleType"
                :active="data.item.active && data.item.contractor.active"
              />
            </template>
          </v-autocomplete>
        </v-list-item>

        <v-list-item v-if="showDrivers">
          <v-autocomplete
            auto-select-first
            :prepend-icon="$store.state.global.pages.driver.icon"
            :label="$store.state.global.pages.driver.titleSingular"
            color="primary"
            :items="filteredDrivers"
            item-text="driverName_contractorName"
            item-value="id"
            v-model="selectedDriverId"
            clearable
            @click:clear="() => $nextTick(() => (selectedDriverId = null))"
            @change="driverOnChanged"
          >
            <template v-slot:item="data">
              <FilterAutoComplete
                :name="data.item.driverName_contractorName"
                :active="data.item.active && data.item.contractor.active"
              />
            </template>
          </v-autocomplete>
        </v-list-item>
        <v-list-item v-if="showDestinations">
          <v-autocomplete
            auto-select-first
            :prepend-icon="$store.state.global.pages.destination.icon"
            :label="$store.state.global.pages.destination.titleSingular"
            color="primary"
            :items="destinations"
            item-text="name"
            item-value="id"
            v-model="selectedDestinationId"
            clearable
            @click:clear="() => $nextTick(() => (selectedDestinationId = null))"
          ></v-autocomplete>
        </v-list-item>
        <v-list-item v-if="showItemTypes">
          <v-autocomplete
            auto-select-first
            :prepend-icon="$store.state.global.pages.itemType.icon"
            :label="$store.state.global.pages.itemType.titleSingular"
            color="primary"
            :items="itemTypes"
            item-text="name"
            item-value="id"
            v-model="selectedItemTypeId"
            clearable
            @click:clear="() => $nextTick(() => (selectedItemTypeId = null))"
          ></v-autocomplete>
        </v-list-item>
        <v-list-item v-if="showUsers">
          <v-autocomplete
            auto-select-first
            :prepend-icon="$store.state.global.pages.user.icon"
            label="Entered By"
            color="primary"
            :items="users"
            item-text="name"
            item-value="id"
            v-model="selectedUserId"
            clearable
            @click:clear="() => $nextTick(() => (selectedUserId = null))"
          ></v-autocomplete>
        </v-list-item>
        <v-list-item v-if="!!reports">
          <v-autocomplete
            auto-select-first
            prepend-icon="mdi-file-document"
            label="Report type"
            color="primary"
            :items="reports"
            item-text="name"
            item-value="id"
            v-model="selectedReportId"
            clearable
            @click:clear="() => $nextTick(() => (selectedReportId = null))"
          ></v-autocomplete>
        </v-list-item>
      </v-list>
      <template v-if="showDateRange">
        <v-list-item>
          <DatePickerWrapper
            label="Start"
            v-model="dateRangeStart"
            :rules="[]"
          />
        </v-list-item>
        <v-list-item>
          <DatePickerWrapper label="End" v-model="dateRangeEnd" :rules="[]" />
        </v-list-item>
      </template>
      <v-list-item v-if="showShowClosed" @click="() => {}">
        <v-list-item-action>
          <v-switch color="primary" v-model="showClosed" inset></v-switch>
        </v-list-item-action>
        <v-list-item-content @click="showClosed = !showClosed">
          <v-list-item-title class="body-2"
            >Show
            {{
              $route.name !== 'rate' ? 'Closed' : 'Inactive'
            }}</v-list-item-title
          >
          <v-list-item-subtitle>{{
            showClosed ? 'Yes!' : 'No'
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="showShowVoid" @click="() => {}">
        <v-list-item-action>
          <v-switch color="primary" v-model="showVoid" inset></v-switch>
        </v-list-item-action>
        <v-list-item-content @click="showVoid = !showVoid">
          <v-list-item-title class="body-2">Show VOID</v-list-item-title>
          <v-list-item-subtitle>{{
            showVoid ? 'Yes!' : 'No'
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item v-if="$route.name == 'docket'" @click="() => {}">
        <v-list-item-action>
          <v-switch color="primary" v-model="showAutoCreated" inset></v-switch>
        </v-list-item-action>
        <v-list-item-content @click="showAutoCreated = !showAutoCreated">
          <v-list-item-title class="body-2"
            >Show Geofence Dockets</v-list-item-title
          >
          <v-list-item-subtitle>{{
            showAutoCreated ? 'Yes!' : 'No'
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item> -->
      <v-list-item v-if="showDeliveryStatus">
        <v-autocomplete
          auto-select-first
          :prepend-icon="$store.state.global.pages.vehicle.icon"
          label="Delivery Status"
          color="primary"
          :items="deliveryStatuses"
          item-value="id"
          v-model="selectedDeliveryStatus"
          clearable
          @click:clear="() => $nextTick(() => (selectedDeliveryStatus = null))"
        ></v-autocomplete>
      </v-list-item>

      <v-list-item v-if="showShowTargets" @click="() => {}">
        <v-list-item-action>
          <v-switch color="primary" v-model="showTargets" inset></v-switch>
        </v-list-item-action>
        <v-list-item-content @click="showTargets = !showTargets">
          <v-list-item-title class="body-2">
            {{
              `Show ${$store.state.global.pages.destination.title.toLowerCase()}`
            }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-navigation-drawer>
</template>

<script>
import DatePickerWrapper from '@/components/misc/fields/DatePickerWrapper'
import FilterAutoComplete from '@/components/misc/shared/FilterAutoComplete'
import FilterHelpers from './mixins/filterHelpers'
export default {
  mixins: [FilterHelpers],
  components: {
    DatePickerWrapper,
    FilterAutoComplete
  },
  props: {
    value: Boolean,
    lineView: Boolean,
    users: {
      type: Array,
      required: false
    },
    contractors: {
      type: Array,
      required: false
    },
    projects: {
      type: Array,
      required: false
    },
    sources: {
      type: Array,
      required: false
    },
    vehicles: {
      type: Array,
      required: false
    },
    drivers: {
      type: Array,
      required: false
    },
    destinations: {
      type: Array,
      required: false
    },
    itemTypes: {
      type: Array,
      required: false
    },
    vehicleTypes: {
      type: Array,
      required: false
    },
    podTypes: {
      type: Array,
      required: false
    },
    reports: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      advancedSearchDialog: false,
      deliveryStatuses: [
        'Allocated',
        'No delivery details',
        'Delivered',
        'On-board'
      ]
    }
  },
  computed: {
    filteredSources() {
      return !this.selectedProjectId || this.$route.name === 'map'
        ? this.sources
        : this.sources.filter(
            ({ projectId }) => projectId == this.selectedProjectId
          )
    },
    filteredDrivers() {
      return !this.selectedContractorId
        ? this.drivers
        : this.drivers.filter(
            ({ contractorId }) => contractorId == this.selectedContractorId
          )
    },
    filteredVehicles() {
      return !this.selectedContractorId
        ? this.vehicles
        : this.vehicles.filter(
            ({ contractorId }) => contractorId == this.selectedContractorId
          )
    }
  },
  methods: {
    drawerInput(val) {
      this.$emit('input', val)
    },
    contractorOnChanged() {
      if (this.$route.name === 'compliance') {
        this.selectedDriverId = null
        this.selectedVehicleId = null
      }
    },
    vehicleOnChanged() {
      if (this.$route.name === 'compliance') {
        this.selectedContractorId = null
        this.selectedDriverId = null
      }
    },
    driverOnChanged() {
      if (this.$route.name === 'compliance') {
        this.selectedContractorId = null
        this.selectedVehicleId = null
      }
    }
  }
}
</script>
