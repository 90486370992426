var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"card-outlined",attrs:{"flat":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"no-data-text":"No lines found","hide-default-footer":"","disable-pagination":"","loading":_vm.loadingLines,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(" Loading records... ")]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('Row',{key:item.id,attrs:{"headers":_vm.headers,"item":item,"defaultDialog":_vm.showEdit && !item.closed ? 'editDialog' : 'detailsDialog',"showEdit":!item.closed && _vm.showEdit,"hideDetails":_vm.hideDetails,"closeParent":_vm.closeParent,"selectedId":_vm.selectedId},scopedSlots:_vm._u([{key:"details",fn:function(ref){
var dialog = ref.dialog;
var close = ref.close;
return [(dialog)?_c('DocketLineDetails',{attrs:{"dialog":dialog,"item":item,"loading":_vm.loading,"close":close,"showBilling":_vm.showBilling}}):_vm._e()]}},(_vm.onEdit)?{key:"edit",fn:function(ref){
var dialog = ref.dialog;
var close = ref.close;
return [(dialog)?_c('DocketLineEdit',{attrs:{"dialog":dialog,"item":item,"date":_vm.date,"type":_vm.type,"sourceId":_vm.sourceId,"vehicleId":_vm.vehicleId,"contractor":_vm.contractor,"loading":_vm.loading,"action":_vm.onEdit,"close":close,"showBilling":_vm.showBilling,"isEditDocket":_vm.isEditDocket,"source":_vm.source,"vehicle":_vm.vehicle}}):_vm._e()]}}:null,(_vm.onDelete)?{key:"delete",fn:function(ref){
var dialog = ref.dialog;
var close = ref.close;
return [_c('DeleteDialog',{attrs:{"dialog":dialog,"page":((_vm.$store.state.global.pages.docket.titleSingular) + " line"),"subject":item,"action":_vm.onDelete,"close":close}})]}}:null],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }