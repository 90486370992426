<template>
  <v-card>
    <FilterDrawer
      style="z-index: 99"
      v-model="drawer"
      :lineView="lineView"
      :users="$store.state.users.usersLite"
      :contractors="$store.state.contractors.contractorsLite"
      :sources="$store.state.sources.sourcesLite"
      :vehicles="$store.state.vehicles.vehiclesLite"
      :drivers="$store.state.drivers.driversLite"
      :destinations="$store.state.destinations.destinationsLite"
      :projects="$store.state.projects.projectsLite"
    />
    <v-toolbar dark color="primary" class="mb-3">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span>
          {{ $store.state.global.pages[$route.name].titleSingular }} Charts
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" @click="drawer = !drawer">
            <v-icon>mdi-filter-variant-plus</v-icon>
          </v-btn>
        </template>
        <span>Filters</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-select
            label="Interval"
            v-model="interval"
            prepend-icon="mdi-calendar"
            :items="['Week', 'Month', 'Billing Period', 'Quarter', 'Year']"
            type="text"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select
            label="Break by"
            v-model="breakBy"
            item-text="text"
            item-value="value"
            prepend-icon="mdi-chart-box"
            :items="masterFiles"
            clearable
            type="text"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <ChipDisplay
          :toggleDrawer="() => (drawer = !drawer)"
          :lineView="lineView"
        />
      </v-row>
      <div class="text-center pt-4">
        <apexchart
          v-if="series.length && !loadingData"
          width="100%"
          height="700"
          :options="chartOptions"
          :series="series"
        ></apexchart>
        <v-card flat class="card-outlined" v-else>
          <v-sheet class="pa-3">
            <v-skeleton-loader
              height="400"
              type="image, paragraph, image"
            ></v-skeleton-loader>
          </v-sheet>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Store from '@/store/computed/storeHelpers'
import VueApexCharts from 'vue-apexcharts'
import { getDocketLinesForChart } from '../../../services/requests/dockets'
import { getBillLinesForChart } from '../../../services/requests/bills'
import { getRctiLinesForChart } from '../../../services/requests/rctis'
import FilterDrawer from '../../../components/misc/shared/FilterDrawer'
import ChipDisplay from '../chips/ChipDisplay'

export default {
  props: {
    title: {
      type: String
    },
    close: {
      type: Function,
      required: true
    },
    lineView: { type: Boolean, default: false }
  },
  components: { apexchart: VueApexCharts, FilterDrawer, ChipDisplay },
  mounted() {
    this.getChartData()
  },
  data() {
    return {
      loadingData: false,
      drawer: false,
      interval: 'Month',
      breakBy: null,
      dataArray: [],
      pageTitle: this.$store.state.global.pages[this.$route.name].title,
      chartType: 'bar',
      categories: []
    }
  },
  watch: {
    interval() {
      this.getChartData()
    },
    breakBy() {
      this.getChartData()
    },
    showClosed() {
      this.getChartData()
    },
    selectedBillingPeriod() {
      this.getChartData()
    },
    selectedUserId() {
      this.getChartData()
    },
    showAutoCreated() {
      this.getChartData()
    },
    selectedProjectId() {
      this.getChartData()
    },
    selectedSourceId() {
      this.getChartData()
    },
    selectedContractorId() {
      this.getChartData()
    },
    selectedDriverId() {
      this.getChartData()
    },
    selectedVehicleId() {
      this.getChartData()
    },
    dateRangeStart() {
      this.getChartData()
    },
    dateRangeEnd() {
      this.getChartData()
    }
  },
  computed: {
    series() {
      return this.dataArray
    },
    showClosed: Store.getSet({ store: 'global', prop: 'showClosed' }),
    showAutoCreated: Store.getSet({ store: 'global', prop: 'showAutoCreated' }),
    selectedSourceId: Store.getSet({
      store: 'global',
      prop: 'selectedSourceId'
    }),
    selectedContractorId: Store.getSet({
      store: 'global',
      prop: 'selectedContractorId'
    }),
    selectedDriverId: Store.getSet({
      store: 'global',
      prop: 'selectedDriverId'
    }),
    selectedVehicleId: Store.getSet({
      store: 'global',
      prop: 'selectedVehicleId'
    }),
    selectedBillingPeriod: Store.getSet({
      store: 'global',
      prop: 'selectedBillingPeriod'
    }),
    selectedProjectId: Store.getSet({
      store: 'global',
      prop: 'selectedProjectId'
    }),
    selectedUserId: Store.getSet({ store: 'global', prop: 'selectedUserId' }),
    dateRangeStart: Store.getSet({ store: 'global', prop: 'dateRangeStart' }),
    dateRangeEnd: Store.getSet({ store: 'global', prop: 'dateRangeEnd' }),
    useDataLabels() {
      return this.categories.length > 15 || this.series.length > 10
        ? false
        : true
    },
    masterFiles() {
      let files = [{ text: 'Project', value: 'project' }]
      const docketFiles = [
        {
          text: this.$store.state.global.pages.source.titleSingular,
          value: 'source'
        },
        {
          text: this.$store.state.global.pages.destination.titleSingular,
          value: 'destination'
        },
        {
          text: this.$store.state.global.pages.itemType.titleSingular,
          value: 'itemType'
        },
        {
          text: this.$store.state.global.pages.contractor.titleSingular,
          value: 'contractor'
        },
        {
          text: this.$store.state.global.pages.driver.titleSingular,
          value: 'driver'
        },
        {
          text: this.$store.state.global.pages.vehicle.titleSingular,
          value: 'vehicle'
        }
      ]
      const billFiles = [
        {
          text: this.$store.state.global.pages.source.titleSingular,
          value: 'source'
        },
        {
          text: this.$store.state.global.pages.destination.titleSingular,
          value: 'destination'
        }
      ]
      const rctiFiles = [
        {
          text: this.$store.state.global.pages.contractor.titleSingular,
          value: 'contractor'
        }
      ]
      if (this.$route.name == 'docket') files = [...files, ...docketFiles]
      if (this.$route.name == 'bill') files = [...files, ...billFiles]
      if (this.$route.name == 'rcti') files = [...files, ...rctiFiles]
      return files
    },
    chartOptions() {
      return {
        chart: {
          height: 700,
          type: this.chartType,
          stacked: this.series?.length > 1 ? true : false
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            dataLabels: {
              position: this.series.length > 1 ? 'center' : 'top' // top, center, bottom
            }
          },
          line: {
            stroke: {
              curve: 'straight',
              width: 4
            }
          }
        },
        stroke: {
          curve: 'straight',
          width: 4
        },
        dataLabels: {
          enabled: this.useDataLabels,
          formatter: val => {
            return '$' + this.addCommas(val.toFixed(2))
          },
          offsetY: this.series.length > 1 ? 0 : -20,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          }
        },

        xaxis: {
          labels: {
            show: true,
            formatter: val => {
              return val
            }
          },
          categories: this.categories,
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5
              }
            }
          },
          tooltip: {
            enabled: true
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: true,
            formatter: val => {
              return '$' + this.addCommas(Number(val).toFixed(2))
            }
          }
        }
      }
    }
  },
  methods: {
    async getChartData() {
      try {
        this.loadingData = true
        const payload = this.buildQuery()
        const result =
          this.$route.name == 'bill'
            ? await getBillLinesForChart({
                ...payload,
                pagination: this.pagination
              })
            : this.$route.name == 'rcti'
            ? await getRctiLinesForChart({
                ...payload,
                pagination: this.pagination
              })
            : await getDocketLinesForChart({
                ...payload,
                pagination: this.pagination
              })
        if (result.data.result.length) {
          const names = result.data.result
            .map(({ Name1, Name2 }) => `${Name1}-${Name2 ? Name2 : ''}`)
            .filter((v, i, a) => a.indexOf(v) === i)
          let dataArrays = []
          const hasSubject = !!result.data.result[0].Subject != undefined
          const subjects = hasSubject
            ? result.data.result
                .map(({ Subject }) => Subject)
                .filter((v, i, a) => a.indexOf(v) === i)
            : []
          for (const item of subjects) {
            const data = []
            const lines = result.data.result.filter(
              ({ Subject }) => Subject == item
            )
            for (const name of names) {
              const values = lines.filter(
                ({ Name1, Name2 }) => `${Name1}-${Name2 ? Name2 : ''}` == name
              )
              data.push(values.length ? values[0].Total : 0)
            }
            dataArrays.push({ name: item ? item : 'Total', type: 'bar', data })
          }
          const costs = []
          for (const name of names) {
            const values = result.data.result
              .filter(
                ({ Name1, Name2 }) => `${Name1}-${Name2 ? Name2 : ''}` == name
              )
              .map(({ Cost }) => Cost)
            const value = values.reduce((accumulator, value) => {
              return accumulator + value
            }, 0)
            costs.push(value)
          }
          const cost = {
            name: 'Cost',
            type: 'line',
            data: costs
          }
          if (dataArrays.length) dataArrays.push(cost)
          this.dataArray = dataArrays.length
            ? dataArrays
            : [
                {
                  name: 'Values',
                  type: 'bar',
                  data: result.data.result.map(({ Total }) => Total.toFixed(2))
                },
                cost
              ]
          this.categories = names
        } else {
          this.dataArray = [
            {
              name: 'Values',
              type: 'bar',
              data: []
            }
          ]
        }
        this.loadingData = false
      } catch (err) {
        console.log(err)
        this.loadingData = false
      }
    },
    buildQuery() {
      return {
        userId: this.$store.state.global.selectedUserId,
        showClosed: this.$store.state.global.showClosed,
        showAutoCreated: this.$store.state.global.showAutoCreated,
        billingPeriod: this.$store.state.global.selectedBillingPeriod,
        projectId: this.$store.state.global.selectedProjectId,
        sourceId: this.$store.state.global.selectedSourceId,
        contractorId: this.$store.state.global.selectedContractorId,
        driverId: this.$store.state.global.selectedDriverId,
        vehicleId: this.$store.state.global.selectedVehicleId,
        dateRangeStart: this.$store.state.global.dateRangeStart,
        dateRangeEnd: this.$store.state.global.dateRangeEnd,
        filterCol: this.$store.state.dockets.filterCol,
        filterBy: this.$store.state.dockets.filterBy,
        interval: this.interval,
        breakBy: this.breakBy
      }
    }
  }
}
</script>
