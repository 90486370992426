<template>
  <v-row class="pt-5">
    <v-col cols="6" class="d-flex align-center">Report footer text</v-col>
    <v-col cols="6" class="d-flex align-center">
      <v-text-field
        :disabled="!userAuthClass"
        v-model="clonedFooterText"
        hide-details
        class="mt-0"
      ></v-text-field>
    </v-col>

    <v-col cols="12" class="d-flex justify-space-between align-center">
      Stop on compliance error
      <v-switch
        :disabled="!userAuthClass"
        inset
        v-model="clonedOtherSettings.stopOnComplianceError"
        hide-details
        class="mt-0"
      ></v-switch>
    </v-col>
    <v-col cols="12" class="d-flex justify-space-between align-center">
      Use inductions ({{ $store.state.global.pages.contractor.title }},
      {{ $store.state.global.pages.driver.title }},
      {{ $store.state.global.pages.vehicle.title }} )
      <v-switch
        :disabled="!userAuthClass"
        inset
        v-model="clonedOtherSettings.useInductions"
        hide-details
        class="mt-0"
      ></v-switch>
    </v-col>
    <v-col cols="12" class="d-flex justify-space-between align-center">
      Limit
      {{ $store.state.global.pages.destination.title.toLowerCase() }} by
      {{ $store.state.global.pages.project.titleSingular.toLowerCase() }}
      <v-switch
        :disabled="!userAuthClass"
        inset
        v-model="clonedOtherSettings.limitDestinations"
        hide-details
        class="mt-0"
      ></v-switch>
    </v-col>
    <v-col cols="12" class="d-flex justify-space-between align-center">
      Limit
      {{ $store.state.global.pages.itemType.title.toLowerCase() }} by
      {{ $store.state.global.pages.destination.titleSingular.toLowerCase() }}
      <v-switch
        :disabled="!userAuthClass"
        inset
        v-model="clonedOtherSettings.limitItemTypes"
        hide-details
        class="mt-0"
      ></v-switch>
    </v-col>

    <v-col cols="12">
      <v-divider></v-divider>
    </v-col>
    <v-col cols="12">
      <v-card-subtitle class="pa-0 font-weight-bold">
        Invoicing ({{ $store.state.global.pages.bill.title }} /
        {{ $store.state.global.pages.rcti.title }})
      </v-card-subtitle>
    </v-col>
    <v-col cols="9" class="d-flex align-center">Create bills by</v-col>
    <v-col cols="3" class="d-flex align-center">
      <v-autocomplete
        auto-select-first
        :disabled="!userAuthClass"
        v-model="clonedOtherSettings.billBy"
        hide-details
        class="mt-0"
        :items="billByItems"
        item-text="name"
        item-value="value"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" class="d-flex justify-space-between align-center">
      Allow approved billing
      <v-switch
        :disabled="!userAuthClass"
        inset
        v-model="clonedOtherSettings.useApprovedBilling"
        hide-details
        class="mt-0"
      ></v-switch>
    </v-col>

    <v-col cols="12" class="d-flex justify-space-between align-center">
      Enable
      {{ $store.state.global.pages.docket.titleSingular.toLowerCase() }}
      confirmations for invoicing
      <v-switch
        :disabled="!userAuthClass"
        inset
        v-model="clonedOtherSettings.useConfirmations"
        hide-details
        class="mt-0"
      ></v-switch>
    </v-col>
    <v-col cols="12" class="d-flex justify-space-between align-center">
      Rate is mandatory in
      {{ $store.state.global.pages.docket.titleSingular.toLowerCase() }}
      entry
      <v-switch
        :disabled="!userAuthClass"
        inset
        v-model="clonedOtherSettings.useRate"
        hide-details
        class="mt-0"
      ></v-switch>
    </v-col>
    <!-- <v-col cols="12" class="d-flex justify-space-between align-center">
      Use billing period for invoicing
      <v-switch
        inset
        v-model="clonedOtherSettings.useBillingPeriod"
        hide-details
        class="mt-0"
      ></v-switch>
    </v-col> -->

    <v-col cols="12">
      <v-divider></v-divider>
    </v-col>

    <v-col cols="12">
      <v-card-subtitle class="pa-0 font-weight-bold">
        Geofences ({{
          $store.state.global.pages.driver.titleSingular.toLowerCase()
        }}
        app)
      </v-card-subtitle>
    </v-col>

    <v-col cols="12" class="d-flex justify-space-between align-center">
      Enable geofences
      <v-switch
        :disabled="!userAuthClass"
        inset
        v-model="clonedOtherSettings.useGeoFences"
        hide-details
        class="mt-0"
      ></v-switch>
    </v-col>
    <v-col cols="12" class="d-flex justify-space-between align-center">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <span v-on="on"
            >Check all
            {{ $store.state.global.pages.destination.titleSingular }}s</span
          >
        </template>
        <span
          >{{ $store.state.global.pages.driver.titleSingular }}
          app will search all
          {{
            $store.state.global.pages.destination.titleSingular.toLowerCase()
          }}s for a geofence match when
          {{ $store.state.global.pages.driver.titleSingular.toLowerCase() }}
          has an active
          {{
            $store.state.global.pages.allocation.titleSingular.toLowerCase()
          }}. <br />If disabled, only
          {{
            $store.state.global.pages.destination.titleSingular.toLowerCase()
          }}s pre-allocated on
          {{
            $store.state.global.pages.allocation.titleSingular.toLowerCase()
          }}s will trigger a geofence match.
        </span>
      </v-tooltip>
      <v-switch
        inset
        :disabled="!userAuthClass || !clonedOtherSettings.useGeoFences"
        v-model="clonedOtherSettings.checkAllDestinations"
        hide-details
        class="mt-0"
      ></v-switch>
    </v-col>
    <v-col cols="12" class="d-flex justify-space-between align-center">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <span v-on="on"
            >Allow
            {{ $store.state.global.pages.driver.titleSingular.toLowerCase() }}
            {{ $store.state.global.pages.allocation.title.toLowerCase() }}
          </span>
        </template>
        <span
          >{{ $store.state.global.pages.driver.titleSingular }}
          app will allow
          {{ $store.state.global.pages.driver.title.toLowerCase() }} to create
          {{ $store.state.global.pages.allocation.title.toLowerCase() }} and
          {{ $store.state.global.pages.docket.title.toLowerCase() }} with no
          pre-{{
            $store.state.global.pages.allocation.titleSingular.toLowerCase()
          }}.
        </span>
      </v-tooltip>
      <v-switch
        :disabled="!userAuthClass"
        inset
        v-model="clonedOtherSettings.allowDriverAllocations"
        hide-details
        class="mt-0"
      ></v-switch>
    </v-col>
    <v-col cols="9" class="d-flex align-center">
      Default geofence radius (m)
    </v-col>
    <v-col cols="3" class="d-flex align-center">
      <v-text-field
        v-model="clonedOtherSettings.defaultGeoFenceRadius"
        hide-details
        type="number"
        class="mt-0"
        :disabled="!userAuthClass || !clonedOtherSettings.useGeoFences"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <ShowAudits :auditData="auditData" />
    </v-col>
  </v-row>
</template>

<script>
import Store from '../../store/computed/storeHelpers'
import getSourcesLite from '../../services/mixins/getMasterFiles/lite/getSourcesLite'
import { updateSettings } from '../../services/requests/settings'
import SetPages from '../../services/mixins/setPages'
import { debounce } from 'lodash'
import ShowAudits from '../misc/shared/ShowAudits'

export default {
  mixins: [getSourcesLite, SetPages],
  components: { ShowAudits },
  props: {
    footerText: {
      type: String,
      required: true
    },
    otherSettings: {
      type: Object,
      required: true
    }
  },
  watch: {
    clonedOtherSettings: {
      deep: true,
      handler() {
        this.saveSettings()
      }
    },
    clonedFooterText: debounce(function() {
      this.saveSettings()
    }, 300)
  },
  data() {
    return {
      clonedFooterText: this.footerText,
      clonedOtherSettings: Object.assign({}, this.otherSettings),
      loading: false,
      billByItems: [
        {
          name: this.$store.state.global.pages.project.titleSingular,
          value: 'PROJECT'
        },
        {
          name: this.$store.state.global.pages.source.titleSingular,
          value: 'SOURCE'
        },
        {
          name: this.$store.state.global.pages.destination.titleSingular,
          value: 'DESTINATION'
        }
      ]
    }
  },
  computed: {
    settings: Store.getSet({ store: 'settings', prop: 'settings' }),
    auditData() {
      return { id: 1, model: 'settings' }
    }
  },
  methods: {
    async saveSettings() {
      this.loadingSettings = true
      const payload = {
        defaultSourceId: this.clonedOtherSettings.defaultSourceId,
        useApprovedBilling: this.clonedOtherSettings.useApprovedBilling,
        useInductions: this.clonedOtherSettings.useInductions,
        limitDestinations: this.clonedOtherSettings.limitDestinations,
        limitItemTypes: this.clonedOtherSettings.limitItemTypes,
        useConfirmations: this.clonedOtherSettings.useConfirmations,
        useRate: this.clonedOtherSettings.useRate,
        useBillingPeriod: this.clonedOtherSettings.useBillingPeriod,
        stopOnComplianceError: this.clonedOtherSettings.stopOnComplianceError,
        checkAllDestinations: this.clonedOtherSettings.checkAllDestinations,
        allowDriverAllocations: this.clonedOtherSettings.allowDriverAllocations,
        billBy: this.clonedOtherSettings.billBy,
        accountingSystem: this.clonedOtherSettings.accountingSystem,
        purchasesAccount: this.clonedOtherSettings.purchasesAccount,
        salesAccount: this.clonedOtherSettings.salesAccount,
        footerText: this.clonedFooterText,
        defaultGeoFenceRadius: Number(
          this.clonedOtherSettings.defaultGeoFenceRadius
        ),
        useGeoFences: this.clonedOtherSettings.useGeoFences
      }
      try {
        this.loading = true
        const result = await updateSettings(
          this.clonedOtherSettings.id,
          payload
        )
        // const useApprovedBilling = result.data.result.useApprovedBilling
        await this.setMainPages()
        this.settings = result.data.result
        this.snack({ text: 'Setting updated', color: 'green' })
        this.loadingSettings = false
        this.loading = false
      } catch (err) {
        this.loading = false
        this.loadingSettings = false
        console.log(err)
      }
    }
  }
}
</script>
