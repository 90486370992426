import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
export const makePdfDoc = async ({
  pdfData,
  docHeading,
  docSubheading,
  columns,
  columnStyles,
  foot,
  pdfName
}) => {
  const doc = new jsPDF('p', 'cm', 'a4')
  const pageWidth = doc.internal.pageSize.width
  const pageHeight = doc.internal.pageSize.height

  //HEADER
  //HEADER
  const img = new Image()
  const imageWidth = pdfData.type != 'rcti' ? 5 : 0
  const imageHeight = 2.5
  const fromStart = imageWidth + 0.75
  img.src = require('../../assets/logo.png')
  if (pdfData.type != 'rcti')
    doc.addImage(img, 'png', 0.5, 0.2, imageWidth, imageHeight)

  // FROM
  // FROM
  doc.setFont('helvetica', 'bold')
  doc.setFontSize(16).text(pdfData.from[0], fromStart, 0.7)
  doc.setFont('helvetica', 'normal').setFontSize(10)
  let y = 1.2
  const from = [...pdfData.from].slice(1)
  doc.text(from, fromStart, y)

  doc.setFontSize(20).setFont('helvetica', 'bold')
  doc.text(docHeading, pageWidth - 0.5, 0.8, { align: 'right' })
  doc.setFontSize(10).setFont('helvetica', 'normal')
  doc.text(docSubheading, pageWidth - 0.5, 1.5, { align: 'right' })

  // TO
  // TO
  doc.setLineWidth(0.02).setDrawColor(180, 180, 180)
  doc.roundedRect(0.5, 3.3, 9.5, 3.9, 0.1, 0.1, 'S')
  doc.setFont('helvetica', 'bold')
  doc.setFontSize(12).text(pdfData.toLabel || 'To:', 0.7, 4)
  doc.setFont('helvetica', 'normal').setFontSize(10)
  y = 4.5
  doc.text(pdfData.to, 0.7, y)

  // INFO
  // INFO
  doc.roundedRect(11, 3.3, pageWidth - 11.5, 3.9, 0.1, 0.1, 'S')
  y = 4
  pdfData.info.forEach(item => {
    doc.setFont('helvetica', 'bold').text(item.key, 11.2, y)
    doc.setFont('helvetica', 'normal')
    doc.text(item.data, pageWidth - 0.7, y, { align: 'right' })
    y = y + 0.5
  })

  // EXTRA INFO
  // EXTRA INFO
  if (pdfData.extraInfo) {
    const spacing = (pageWidth - 1) / pdfData.extraInfo.length
    doc.roundedRect(0.5, 7.5, pageWidth - 1, 1.25, 0.1, 0.1, 'S')
    let x = 0.7
    pdfData.extraInfo.forEach(item => {
      doc.setFont('helvetica', 'bold').text(item.key, x, 8)
      if (item.data) doc.setFont('helvetica', 'normal').text(item.data, x, 8.5)
      x = x + spacing
    })
  }

  // AUTOTABLE LINES
  // AUTOTABLE LINES
  doc.autoTableSetDefaults({
    headStyles: { fillColor: [180, 180, 180], halign: 'center' },
    styles: { halign: 'left', fontSize: 8 },
    margin: { left: 0.5, top: 1.25, right: 0.5, bottom: 3 },
    showFoot: 'lastPage'
  })
  if (pdfData.lines.length) {
    autoTable(doc, {
      startY: 9.2,
      columns,
      body: pdfData.lines,
      columnStyles,
      foot,
      footStyles: {
        fontSize: 14,
        textColor: [10, 10, 10],
        fillColor: [255, 255, 255],
        halign: 'right'
      },
      styles: {
        lineWidth: 0.01
      },
      theme: 'grid'
    })
  }

  // FOOTER
  // FOOTER
  const footerStart = pageHeight - 4.75
  doc.roundedRect(0.5, footerStart, pageWidth - 1, 1.75, 0.1, 0.1, 'S')
  doc.setFont('helvetica', 'bold')
  doc.setFontSize(11).text('NOTES', 0.7, footerStart + 0.5)
  doc.setFont('helvetica', 'normal')
  const splitNotes = doc.splitTextToSize(pdfData.invoiceNotes, pageWidth - 1.5)
  doc.text(splitNotes, 0.7, footerStart + 1)
  const footTextWidth = doc
    .setFontSize(8)
    .getTextWidth(pdfData.footerText.toString())
  const centerFooterX = pageWidth / 2 - footTextWidth / 2

  const pageCount = doc.internal.getNumberOfPages() //Total Page Number
  for (let i = 0; i < pageCount; i++) {
    doc.setPage(i)
    let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber //Current Page
    doc
      .setFontSize(8)
      .text(
        `Page: ${pageCurrent} / ${pageCount}`,
        pageWidth - 0.5,
        footerStart + 2.5,
        { align: 'right' }
      )
    doc.text(`Doc ID: ${pdfData.info[0].data}`, 0.5, footerStart + 2.5)
    doc
      .setFontSize(8)
      .text(
        pdfData.footerText,
        centerFooterX >= 0 ? centerFooterX : 0.5,
        footerStart + 2.5
      )
  }
  doc.save(pdfName)
}
