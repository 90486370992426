<template>
  <v-card flat class="card-outlined">
    <v-data-table
      :headers="headers"
      :items="items"
      no-data-text="No lines found"
      hide-default-footer
      disable-pagination
      :loading="loadingLines"
      mobile-breakpoint="0"
    >
      <template v-slot:loading>
        Loading records...
      </template>
      <!-- <template v-slot:top>
        <div class="text-right mr-2 mt-2">
          <v-tooltip top v-if="showEdit">
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                v-on="on"
                text
                @click="$emit('showAddLineDialog')"
              >
                ADD LINE
                <v-icon right dark class="ml-2">
                  mdi-plus-circle-outline
                </v-icon>
              </v-btn>
            </template>
            <span>
              Add new line
            </span>
          </v-tooltip>
        </div>
      </template> -->
      <template v-slot:item="{ item }">
        <Row
          :headers="headers"
          :item="item"
          :key="item.id"
          :defaultDialog="
            showEdit && !item.closed ? 'editDialog' : 'detailsDialog'
          "
          :showEdit="!item.closed && showEdit"
          :hideDetails="hideDetails"
          :closeParent="closeParent"
          :selectedId="selectedId"
        >
          <template v-slot:details="{ dialog, close }">
            <DocketLineDetails
              v-if="dialog"
              :dialog="dialog"
              :item="item"
              :loading="loading"
              :close="close"
              :showBilling="showBilling"
            />
          </template>
          <template v-if="onEdit" v-slot:edit="{ dialog, close }">
            <DocketLineEdit
              v-if="dialog"
              :dialog="dialog"
              :item="item"
              :date="date"
              :type="type"
              :sourceId="sourceId"
              :vehicleId="vehicleId"
              :contractor="contractor"
              :loading="loading"
              :action="onEdit"
              :close="close"
              :showBilling="showBilling"
              :isEditDocket="isEditDocket"
              :source="source"
              :vehicle="vehicle"
            />
          </template>
          <template v-if="onDelete" v-slot:delete="{ dialog, close }">
            <DeleteDialog
              :dialog="dialog"
              :page="`${$store.state.global.pages.docket.titleSingular} line`"
              :subject="item"
              :action="onDelete"
              :close="close"
            />
          </template>
        </Row>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Row from '../../misc/row/Row'
import DocketLineDetails from './DocketLineDetails'
import DocketLineEdit from './DocketLineEdit'
import DeleteDialog from '../../misc/DeleteDialog'
export default {
  components: {
    Row,
    DocketLineDetails,
    DocketLineEdit,
    DeleteDialog
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
    vehicleId: {
      type: Number,
      required: false
    },
    sourceId: {
      type: Number,
      required: false
    },
    date: {
      type: String,
      required: false
    },
    contractor: {
      type: Object,
      required: false
    },
    items: {
      type: Array,
      required: true
    },
    showEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    onEdit: {
      type: Function,
      required: false
    },
    onDelete: {
      type: Function,
      required: false
    },
    loading: {
      type: Boolean,
      required: true
    },
    loadingLines: {
      type: Boolean,
      required: false,
      default: false
    },
    closeParent: {
      type: Function,
      required: false
    },
    selectedId: {
      type: Number,
      required: false
    },
    type: {
      type: String,
      required: false
    },
    isEditDocket: {
      type: Boolean,
      required: false,
      default: false
    },
    showBilling: {
      type: Boolean,
      required: false
    },
    source: {
      type: Object,
      required: false
    },
    vehicle: {
      type: Object,
      required: false
    }
  }
}
</script>
