<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span>
          Edit
          {{
            editedItem.isStaged ? `(staged) ${titleSingular}` : titleSingular
          }}
          -
          <span class="pl-1 subtitle-1">
            ID: <span class="font-weight-bold">{{ item.id }}</span>
          </span>
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-sheet outlined rounded>
            <v-card flat class="card-outlined">
              <v-form ref="editForm" @submit.prevent="edit()">
                <v-card-text class="pb-0">
                  <v-row class="pt-3">
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        label="Reference"
                        prepend-icon="mdi-group"
                        v-model="editedItem.reference"
                        :rules="referenceRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <DatePickerWrapper
                        label="* Date"
                        v-model="editedItem.date"
                        :rules="dateRules"
                        :disabled="editedItem.void"
                      />
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        label="Start Time"
                        disabled
                        prepend-icon="mdi-clock"
                        v-model="editedItem.start"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-autocomplete
                        auto-select-first
                        :label="
                          `* ${$store.state.global.pages.source.titleSingular}`
                        "
                        :prepend-icon="$store.state.global.pages.source.icon"
                        v-model="editedItem.sourceId"
                        :items="sources.filter(i => i.project.active)"
                        item-value="id"
                        item-text="sourceName_projectName"
                        clearable
                        :rules="sourceRules"
                        :disabled="editedItem.void"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        label="Travel Mode"
                        disabled
                        :prepend-icon="$store.state.global.pages.vehicle.icon"
                        v-model="editedItem.travelMode"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        label="Notes"
                        prepend-icon="mdi-note"
                        v-model="editedItem.notes"
                        :rules="notesRules"
                        :disabled="editedItem.void"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <v-switch
                        :label="editedItem.isDay ? 'Day' : 'Night'"
                        color="primary"
                        v-model="editedItem.isDay"
                        inset
                        :disabled="editedItem.void"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row v-if="allowOptimise">
                    <v-col cols="12" v-if="allowedPlanVehicles.length">
                      <v-select
                        v-model="planVehicles"
                        :items="allowedPlanVehicles"
                        item-text="vehicleName_contractorName_vehicleType"
                        item-value="id"
                        chips
                        return-object
                        multiple
                        label="Pre-allocated Vehicles"
                        prepend-inner-icon="mdi-truck"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text color="grey darken-1" @click="close"
                    >Cancel</v-btn
                  >
                  <v-tooltip top :disabled="!duplicatedVehicles.length">
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-btn
                          text
                          :disabled="!!duplicatedVehicles.length"
                          color="primary"
                          type="submit"
                          :loading="loading"
                        >
                          Save
                        </v-btn>
                      </div>
                    </template>
                    <span>
                      Remove duplicated
                      {{
                        $store.state.global.pages.vehicle.title.toLowerCase()
                      }}
                    </span>
                  </v-tooltip>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-sheet>
        </v-col>
        <v-col>
          <PlanLineAdd
            :lines="planLines"
            :onAdd="addPlanLine"
            :onEdit="onEdit"
            :onDelete="onDelete"
            :loading="loading"
            :loadingLines="loadingLines"
            isEditPlan
            :lineHeaders="lineHeaders"
            :sources="sources.filter(i => i.project.active)"
            :vehicles="vehicles.filter(i => i.contractor.active && i.active)"
            :drivers="drivers.filter(i => i.contractor.active && i.active)"
            :destinations="destinations"
            :itemTypes="itemTypes"
            :sourceId="editedItem.sourceId"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Vue from 'vue'
import Store from '../../store/computed/storeHelpers'
import {
  getLinesByPlanId,
  updatePlanLine,
  addPlanLine,
  deletePlanLine
} from '../../services/requests/plans'
import PlanValidation from '@/services/validation/plan'
import LineHeaders from './lines/mixins/lineHeaders'
import HeaderFilters from '../../services/mixins/headerFilters'
import FilterByContractor from '../../services/mixins/filterByContractor'
import PlanLine from '../../services/models/planLine'
import PlanLineAdd from '../plans/lines/PlanLineAdd'

import DatePickerWrapper from '../misc/fields/DatePickerWrapper'
export default {
  mixins: [PlanValidation, LineHeaders, HeaderFilters, FilterByContractor],
  components: {
    DatePickerWrapper,
    PlanLineAdd
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  mounted() {
    this.getLines()
    this.setPlanVehicles()
  },
  data() {
    return {
      editedItem: Object.assign({}, this.item),
      planLines: [],
      loadingLines: false,
      addLineDialog: false,
      planVehicles: [],
      allowOptimise: process.env.VUE_APP_ALLOW_OPTIMISE === 'true'
    }
  },
  computed: {
    sources: Store.getSet({ store: 'sources', prop: 'sourcesLite' }),
    vehicles: Store.getSet({ store: 'vehicles', prop: 'vehiclesLite' }),
    vehiclesWithDriver: Store.getSet({
      store: 'vehicles',
      prop: 'vehiclesWithDriverLite'
    }),
    drivers: Store.getSet({ store: 'drivers', prop: 'driversLite' }),
    destinations: Store.getSet({
      store: 'destinations',
      prop: 'destinationsLite'
    }),
    itemTypes: Store.getSet({ store: 'itemTypes', prop: 'itemTypesLite' }),
    lineHeaders() {
      return Number(this.window?.width) >= 600
        ? this.allPossibleHeaders
        : this.mobileHeaders
    },
    allowedPlanVehicles() {
      if (!this.vehiclesWithDriver || !this.editedItem.sourceId) return []
      const source = this.sources.find(
        ({ id }) => this.editedItem.sourceId == id
      )
      const allowedVehicles = this.vehiclesWithDriver.filter(({ id }) =>
        source.allowedVehicles.includes(id)
      )
      return allowedVehicles
    },
    duplicatedVehicles() {
      const vehicleNames = this.planVehicles.map(({ name }) => name)
      const dup = vehicleNames.filter(
        (item, index) => vehicleNames.indexOf(item) !== index
      )
      return dup
    }
  },
  watch: {
    item() {
      this.editedItem = Object.assign({}, this.item)
    },
    editedItem: {
      deep: true,
      handler() {
        if (this.item.sourceId !== this.editedItem.sourceId) {
          this.planVehicles = this.allowedPlanVehicles
        }
      }
    }
  },
  methods: {
    async getLines() {
      try {
        this.loadingLines = true
        const result = await getLinesByPlanId(this.item.id)
        const items = result.data.result
        this.planLines = items.map(item => new PlanLine(item))
        this.loadingLines = false
      } catch (err) {
        console.log(err)
      }
    },
    setPlanVehicles() {
      const currentPlanVehicles = this.editedItem.planVehicles
        ? JSON.parse(this.editedItem.planVehicles)
        : []
      for (const planVehicle of currentPlanVehicles) {
        const vehicle = this.allowedPlanVehicles.find(allowedVehicle => {
          return (
            allowedVehicle.id === planVehicle.vehicleId &&
            (planVehicle.driverId
              ? allowedVehicle.driver.id === planVehicle.driverId
              : allowedVehicle.driver == null)
          )
        })
        this.planVehicles.push(vehicle)
      }
    },
    async edit() {
      if (!this.$refs.editForm.validate()) {
        this.snackFormError()
        return
      }
      const planVehicles = this.planVehicles.map(item => {
        return {
          vehicleId: item.id,
          driverId: item.driver ? item.driver.id : null
        }
      })
      const planVehiclesStr = JSON.stringify(planVehicles)
      this.editedItem.planVehicles = planVehiclesStr
      const payload = {
        ...this.editedItem
      }
      await this.action(payload)
      this.close()
    },
    async onEdit({ item }) {
      try {
        this.loadingLines = true
        const payload = {
          planId: item.planId,
          destinationId: item.destinationId,
          itemTypeId: item.itemTypeId,
          vehicleId: item.vehicleId,
          driverId: item.driverId,
          waypointIndex: item.waypointIndex,
          delTime: item.delTime,
          qty: item.qty,
          notes: item.notes,
          addressStreet1: item.addressStreet1,
          addressStreet2: item.addressStreet2,
          addressCity: item.addressCity,
          addressState: item.addressState
        }
        const result = await updatePlanLine(item.id, payload)
        if (result) {
          const i = this.planLines.findIndex(line => line.id === item.id)
          if (~i) {
            const editedPlanLine = new PlanLine(result.data.result)
            Vue.set(this.planLines, i, editedPlanLine)
            this.$store.commit(`plans/updateArrayStateById`, {
              prop: 'lines',
              editedValue: editedPlanLine
            })
          }
          this.snack({
            text: `${this.titleSingular} line updated`,
            color: 'green'
          })
        }
        this.loadingLines = false
        return result
      } catch (err) {
        this.loadingLines = false
        console.log(err)
      }
    },
    async addPlanLine(line) {
      try {
        this.loadingLines = true
        const payload = {
          destinationId: line.destinationId,
          itemTypeId: line.itemTypeId,
          vehicleId: line.vehicleId,
          driverId: line.driverId,
          notes: line.notes,
          planId: this.editedItem.id,
          addressStreet1: line.addressStreet1,
          addressStreet2: line.addressStreet2,
          addressCity: line.addressCity,
          addressState: line.addressState
        }
        const result = await addPlanLine(payload)
        if (result) {
          await this.getLines()
          const newPlanLine = new PlanLine(result.data.line)
          this.$store.commit('plans/addToArrayState', {
            prop: 'lines',
            value: newPlanLine
          })
          this.$store.commit('plans/updateArrayStateById', {
            prop: 'plans',
            editedValue: result.data.result
          })
          this.snack({
            text: `${this.titleSingular} line created`,
            color: 'green'
          })
        }
        this.loadingLines = false
        return result
      } catch (err) {
        this.loadingLines = false
        console.log(err)
      }
    },
    async onDelete(line) {
      try {
        this.loadingLines = true
        const result = await deletePlanLine(line.id)
        if (result) {
          this.$store.commit('plans/deleteFromArrayStateById', {
            prop: 'lines',
            id: line.id
          })
          this.$store.commit('plans/updateArrayStateById', {
            prop: 'plans',
            editedValue: result.data.result
          })
          await this.getLines()
          this.snack({
            text: `${this.titleSingular} line deleted`,
            color: 'amber'
          })
        }
        this.loadingLines = false
        return result
      } catch (err) {
        this.loadingLines = false
        console.log(err)
      }
    }
  }
}
</script>
