<template>
  <div>
    <h3>TIME</h3>
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <TimePickerWrapper
          v-model="start"
          refString="start"
          label="Start Time"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <TimePickerWrapper v-model="end" label="End Time" />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          color="indigo"
          label="Breaks (-)"
          prepend-icon="mdi-alarm_off"
          v-model.number="breaks"
          :rules="breaksRules"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          color="indigo"
          label="Travel (+)"
          prepend-icon="mdi-add_alarm"
          v-model.number="travel"
          :rules="travelRules"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-range-slider
          v-if="rangeList.length"
          :tick-labels="rangeList"
          v-model="selectedRange"
          :ticks="false"
          :value="[0, 96]"
          min="0"
          max="96"
          step="1"
          tick-size="2"
        >
          <template v-slot:thumb-label="props">
            <v-row>
              <v-col>
                {{ timesList[props.value] }}
              </v-col>
            </v-row>
          </template>
        </v-range-slider>
      </v-col>
      <v-col cols="12">
        <span class="title" v-if="hours">Total: {{ hours }} hours</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TimePickerWrapper from '../../misc/fields/TimePickerWrapper'
export default {
  components: {
    TimePickerWrapper
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    clearTimes: {
      type: Boolean,
      required: false
    },
    hours: {
      type: Number,
      required: false
    },
    breaksRules: {
      type: Array,
      required: true
    },
    travelRules: {
      type: Array,
      required: true
    },
    onChange: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      start: null,
      end: null,
      breaks: 0,
      travel: 0,
      selectedRange: [48, 48],
      rangeList: [],
      timesList: []
    }
  },
  mounted() {
    for (const key of Object.keys(this.values)) {
      this[key] = this.values[key]
    }
  },
  watch: {
    selectedRange(val) {
      this.start = this.timesList[val[0]]
      this.end = this.timesList[val[1]]
    },
    values: {
      handler(val) {
        if (val.selectedRange) {
          this.selectedRange = val.selectedRange
        }
      },
      deep: true
    },
    start(val) {
      this.onChange('start', val)
    },
    end(val) {
      this.onChange('end', val)
    },
    breaks(val) {
      this.onChange('breaks', val)
    },
    travel(val) {
      this.onChange('travel', val)
    },
    clearTimes() {
      this.breaks = 0
      this.travel = 0
      this.selectedRange = [48, 48]
    }
  }
}
</script>
