import {
  getAllocations,
  updateAllocation,
  makeAllocationVoid,
  deleteAllocation,
  deleteAllocationLine
} from '../../../services/requests/allocations'
import Allocation from '../../../services/models/allocation'
import AllocationLine from '../../../services/models/allocationLine'

export default {
  methods: {
    toggleSelected(val) {
      if (val.value) {
        this.selected.push(val.object)
      } else {
        const index = this.selected.indexOf(val.object)
        this.selected.splice(index, 1)
      }
    },
    async editAllocation(allocation) {
      try {
        this.loading = true
        const payload = {
          reference: allocation.reference,
          date: allocation.date,
          endDate: allocation.endDate,
          isDay: allocation.isDay,
          sourceId: allocation.sourceId,
          vehicleId: allocation.vehicleId,
          driverId: allocation.driverId,
          notes: allocation.notes,
          isStaged: allocation.isStaged,
          sourceConfirmed: allocation.sourceConfirmed,
          sourceNotes: allocation.sourceNotes,
          destinationConfirmed: allocation.destinationConfirmed,
          destinationNotes: allocation.destinationNotes,
          amendmentsConfirmed: allocation.amendmentsConfirmed,
          amendmentsNotes: allocation.amendmentsNotes
        }
        const result = await updateAllocation(allocation.id, payload)
        if (result) {
          this.$store.commit('allocations/updateArrayStateById', {
            prop: 'allocations',
            editedValue: new Allocation(result.data.result)
          })
          if (result.data.lines.length) {
            for (const line of result.data.lines) {
              this.$store.commit('allocations/updateArrayStateById', {
                prop: 'lines',
                editedValue: new AllocationLine(line)
              })
            }
          }
          if (this.events) this.getEvents()
          this.snackUpdated()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    async makeAllocationVoid(id, voidNotes) {
      try {
        this.loading = true
        const payload = { voidNotes }
        const result = await makeAllocationVoid(id, payload)
        if (result) {
          this.$store.commit('allocations/updateArrayStateById', {
            prop: 'allocations',
            editedValue: new Allocation(result.data.result)
          })
          this.snackUpdated()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    async deleteAllocation(allocation) {
      try {
        this.loading = true
        const result = await deleteAllocation(allocation.id)
        if (result) {
          this.$store.commit('allocations/deleteFromArrayStateByValue', {
            prop: 'allocations',
            value: allocation
          })
          this.snackDeleted()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    async deleteAllocationLine(line) {
      try {
        this.loading = true
        const result = await deleteAllocationLine(line.id)
        if (result) {
          this.$store.commit('allocations/deleteFromArrayStateByValue', {
            prop: 'lines',
            value: line
          })
          this.snackDeleted()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    async getAllSelected() {
      try {
        const payload = this.buildQuery()
        const result = await getAllocations(payload)
        return result.data.result
      } catch (err) {
        console.log(err)
      }
    }
  }
}
