<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="grey lighten-4">
      <div class="font-weight-medium grey--text text--darken-2">
        <v-icon color="primary" class="mr-2">
          mdi-weather-night
        </v-icon>
        Night Rate
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12"><h4>Night Cost</h4></v-col>
        <v-col cols="6">
          <v-text-field
            type="number"
            label="* Cost price"
            :rules="priceRules"
            v-model.number="costPriceNight"
            prepend-icon="mdi-currency-usd"
            color="primary"
            @change="() => onChange('costPriceNight', costPriceNight)"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="d-flex justify-space-between">
          <span class="pt-4 pr-3">/</span>
          <v-autocomplete
            auto-select-first
            label="Cost unit"
            :items="units"
            v-model="costUnitNight"
            no-data-text="No data found"
            color="primary"
            @change="() => onChange('costUnitNight', costUnitNight)"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12"><h4>Night Sell</h4></v-col>
        <v-col cols="6">
          <v-text-field
            type="number"
            label="* Sell price"
            :rules="priceRules"
            v-model.number="sellPriceNight"
            prepend-icon="mdi-currency-usd"
            color="primary"
            @change="() => onChange('sellPriceNight', sellPriceNight)"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="d-flex justify-space-between">
          <span class="pt-4 pr-3">/</span>
          <v-autocomplete
            auto-select-first
            label="Sell unit"
            :items="units"
            v-model="sellUnitNight"
            no-data-text="No data found"
            color="primary"
            @change="() => onChange('sellUnitNight', sellUnitNight)"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    values: {
      type: Object,
      required: true
    },
    units: {
      type: Array,
      required: true
    },
    priceRules: {
      type: Array,
      required: true
    },
    onChange: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      costPriceNight: 0,
      costUnitNight: null,
      sellPriceNight: 0,
      sellUnitNight: null
    }
  },
  mounted() {
    for (const key of Object.keys(this.values)) {
      this[key] = this.values[key]
    }
  }
}
</script>
